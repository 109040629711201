import React, { useState, useEffect, useRef, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, Element } from 'react-scroll';

import Industria from "./industria";
import Comercio from "./comercio";
import AgroNegocio from "./agronegocio";
import Madeireira from "./madeireira";
import "./Solucoes.css";
import Servicos from "./servicos";

export default function Solucoes() {
  const [activeSection, setActiveSection] = useState("");
  const sectionRefs = useRef([]);

  const sections = useMemo(
    () => [
      { id: "comercio", label: "Comércio", component: Comercio },
      { id: "agronegocio", label: "Agronegócio", component: AgroNegocio },
      { id: "industria", label: "Indústria", component: Industria },
      { id: "madeireira", label: "Madeireira", component: Madeireira },
      { id: "servicos", label: "Servicos", component: Servicos },
    ],
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const navHeight = document.querySelector(".solucoes-nav").offsetHeight;
      const middleOfScreen = scrollPosition + windowHeight / 2;

      let currentSection = "";
      for (let i = 0; i < sectionRefs.current.length; i++) {
        const section = sectionRefs.current[i];
        if (section && section.current) {
          const h2Element = section.current.querySelector("h2");
          if (h2Element) {
            const h2Position = h2Element.getBoundingClientRect().top + window.scrollY;

            if (middleOfScreen >= h2Position - navHeight) {
              currentSection = sections[i].id;
            } else {
              break;
            }
          }
        }
      }

      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sections, activeSection]);

  return (
    <div className="solucoes-container">
      <Helmet>
        <title>Soluções FortePlus - ERP para Diversos Setores</title>
        <meta
          name="description"
          content="Descubra as soluções FortePlus para comércio, agronegócio, indústria, madeireira e serviços. ERP completo e personalizado para cada setor."
        />
        <meta
          name="keywords"
          content="ERP, soluções empresariais, comércio, agronegócio, indústria, madeireira, serviços"
        />
        <meta
          property="og:title"
          content="Soluções FortePlus - ERP para Diversos Setores"
        />
        <meta
          property="og:description"
          content="ERP completo e personalizado para comércio, agronegócio, indústria, madeireira e serviços."
        />
        <meta
          property="og:image"
          content="https://www.forteplus.com.br/images/og-image.png"
        />
        <meta
          property="og:url"
          content="https://www.forteplus.com.br/solucoes"
        />
        <link rel="canonical" href="https://www.forteplus.com.br/solucoes" />
      </Helmet>

      <nav className="solucoes-nav">
        <ul className="solucoes-nav-list">
          {sections.map((section) => (
            <li key={section.id} className="solucoes-nav-item">
              <Link
                to={section.id}
                smooth={true}
                duration={500}
                offset={-60} // Ajuste conforme necessário para compensar a altura do nav
                spy={true}
                activeClass="active"
                className={`solucoes-nav-button ${activeSection === section.id ? 'active' : ''}`}
              >
                <span className="solucoes-nav-icon">
                  {section.id === "comercio" && (
                    <i className="fas fa-store"></i>
                  )}
                  {section.id === "agronegocio" && (
                    <i className="fas fa-tractor"></i>
                  )}
                  {section.id === "industria" && (
                    <i className="fas fa-industry"></i>
                  )}
                  {section.id === "madeireira" && (
                    <i className="fas fa-tree"></i>
                  )}
                  {section.id === "servicos" && <i className="fas fa-cogs"></i>}
                </span>
                <span className="solucoes-nav-label">{section.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="solucoes-content">
        {sections.map((section, index) => (
          <Element
            key={section.id}
            name={section.id}
            className="solucao-section"
            ref={(el) => (sectionRefs.current[index] = React.createRef(el))}
          >
            <h2>{section.label}</h2>
            <section.component />
          </Element>
        ))}
      </div>
    </div>
  );
}