import React, { useState, useEffect } from "react";
import "./industria.css";
import ContatoModal from "../../../componentes/ContatoModal";
export default function Industria() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const images = [
    "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&h=1080&q=80",
    "https://images.unsplash.com/photo-1581092160562-40aa08e78837?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&h=1080&q=80",
    "https://images.unsplash.com/photo-1581092334651-ddf26d9a09d0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&h=1080&q=80",
    "https://images.unsplash.com/photo-1581092160607-ee22621dd758?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&h=1080&q=80",
    "https://images.unsplash.com/photo-1581093588401-fbb62a02f120?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&h=1080&q=80",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setFadeIn(true);
      }, 500); // Metade do tempo de transição
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const caracteristicas = [
    {
      icon: "fa-truck",
      title: "Controle de Romaneio",
      description:
        "Gerencie toda a cadeia de distribuição dos produtos, definindo lotes, fechamento de carga, rotas e controle de entrega.",
    },
    {
      icon: "fa-file-invoice",
      title: "Orçamentos",
      description:
        "Elabore orçamentos diretamente para seus clientes, com opções de alteração, impressão e envio.",
    },
    {
      icon: "fa-cubes",
      title: "Composição de Produto",
      description:
        "Gerencie produtos e matérias-primas necessárias para montagem, incluindo estoque, quantidade e capacidade de produção.",
    },
    {
      icon: "fa-calendar-check",
      title: "Controle Matéria-Prima",
      description:
        "Gerencie o controle de vencimentos e lotes dos produtos de matéria-prima, embalagens e demais itens para controle de produção.",
    },
    {
      icon: "fa-chart-line",
      title: "Financeiro",
      description:
        "Controle completo de suas finanças integrado com o gerenciamento de contas a receber, a pagar e o fluxo de caixa.",
    },
    {
      icon: "fa-file-alt",
      title: "SPED",
      description:
        "Padronização e compartilhamento dos seus dados contábeis e fiscais para agilizar o gerenciamento tributário.",
    },
    {
      icon: "fa-file-invoice-dollar",
      title: "Emissão de NF-e e NFS-e",
      description:
        "Emissão automática de notas fiscais com informações extraídas diretamente do sistema.",
    },
    {
      icon: "fa-shopping-cart",
      title: "Compras",
      description:
        "Importação de informações da nota fiscal de entrada para agilizar o gerenciamento de compras e estoque.",
    },
  ];

  return (
    <div className="industria-container">
      <header className="industria-header">
        <div className="header-content">
          <h1>Gestão ERP para Pequenas e Médias Indústrias</h1>
          <p>
            Otimize seu processo de produção, reduza custos e aumente a
            eficiência com o FortePlus. Ideal para fábricas que necessitam de um
            gerenciamento de controle e qualificação para seu negócio.
          </p>
          <button className="cta-button" onClick={openModal}>
            Fale com um consultor
          </button>
        </div>
        <div className="header-image">
          <img
            src={images[currentImageIndex]}
            alt={`Indústria FortePlus ${currentImageIndex + 1}`}
            className={fadeIn ? "fade-in" : "fade-out"}
          />
        </div>
      </header>

      <section className="vantagens-section">
        <h2>Por que escolher o FortePlus para sua indústria?</h2>
        <div className="vantagens-grid">
          <div className="vantagem-item">
            <i className="fas fa-cogs"></i>
            <h3>Otimização de Processos</h3>
            <p>
              Aumente a eficiência operacional com ferramentas específicas para
              o setor industrial.
            </p>
          </div>
          <div className="vantagem-item">
            <i className="fas fa-chart-bar"></i>
            <h3>Controle Total</h3>
            <p>
              Tenha visibilidade completa sobre produção, estoque, vendas e
              finanças em tempo real.
            </p>
          </div>
          <div className="vantagem-item">
            <i className="fas fa-dollar-sign"></i>
            <h3>Redução de Custos</h3>
            <p>
              Identifique gargalos e otimize recursos para maximizar a
              lucratividade.
            </p>
          </div>
          <div className="vantagem-item">
            <i className="fas fa-tasks"></i>
            <h3>Gestão Simplificada</h3>
            <p>
              Interface intuitiva que facilita o gerenciamento de todos os
              aspectos do seu negócio.
            </p>
          </div>
        </div>
      </section>

      <section className="caracteristicas-section">
        <h2>Características do Sistema para Indústria</h2>
        <div className="caracteristicas-grid">
          {caracteristicas.map((item, index) => (
            <div key={index} className="caracteristica-item">
              <i className={`fas ${item.icon}`}></i>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="ordem-producao-section">
        <div className="ordem-producao-content">
          <div className="ordem-producao-text">
            <h2>Ordem de Produção</h2>
            <p>
              O FortePlus possibilita a gestão da Ordem de Produção para
              produtos acabados, controlando a baixa dos estoques de produtos
              intermediários, embalagens, rótulos, etiquetas e todos os
              elementos que fazem parte deste processo.
            </p>
            <p>
              A empresa terá agilidade na emissão dos relatórios bem como
              assertividade para produzir o que está na maior demanda de
              consumo.
            </p>
            <button className="cta-button" onClick={openModal}>
              Saiba mais
            </button>
          </div>
          <div className="ordem-producao-image">
            <div className="sistema-mockup">
              <div className="sistema-header">
                <div className="sistema-controls">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="sistema-title">
                  FortePlus Web - Ordem de Produção
                </div>
              </div>
              <img src="/images/ordemproducao.png" alt="Ordem de Produção" />
            </div>
          </div>
        </div>
      </section>

      <section className="case-study-section">
        <h2>Case de Sucesso</h2>
        <div className="case-study-content">
          <img
            src="./images/Industria/case-industria.jpg"
            alt="Indústria Cliente"
          />
          <div className="case-study-text">
            <h3>
              Empresas industriais aumentam eficiência em até{" "}
              <span style={{ color: "var(--secondary-color)" }}>25%</span>
            </h3>
            <p>
              "Depois de implementar o FortePlus, alcançamos uma melhoria
              significativa em nossos processos, reduzindo perdas e aumentando
              substancialmente nossa eficiência. O sistema nos forneceu uma
              visão clara de toda a operação, permitindo tomar decisões mais
              informadas."
            </p>
            <p style={{ justifySelf: "end", alignSelf: "end" }}>
              <strong>Gerente de Produção - Empresa Parceira</strong>
            </p>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <h2>Pronto para revolucionar sua indústria?</h2>
        <p>Descubra como o FortePlus pode impulsionar seu negócio.</p>
        <button
          className="cta-button"
          style={{
            margin: "0 auto",
          }}
          onClick={openModal}
        >
          Agende uma demonstração gratuita
        </button>
      </section>

      <ContatoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        nomeTela="Indústria"
      />
    </div>
  );
}
