import React, { useState } from "react";
import "./madeireira.css";
import ContatoModal from "../../../componentes/ContatoModal";

export default function Madeireira() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const funcionalidades = [
    {
      icon: "fa-cube",
      title: "Notas Fiscais por m³",
      description: "Emissão de Notas em conformidade com as regras de vendas de madeiras de lei, destacadas em m³ para facilitar informações ao IBAMA."
    },
    {
      icon: "fa-file-invoice",
      title: "Pedidos de Vendas",
      description: "Pedidos personalizados por unidade de produto, incluindo largura, altura e espessura das madeiras."
    },
    {
      icon: "fa-chart-line",
      title: "Gestão Financeira",
      description: "Controle de contas a pagar e receber, com opção de integração e conciliação bancária completa."
    },
    {
      icon: "fa-file-alt",
      title: "SPED Fiscal ou SINTEGRA",
      description: "Geração prática de obrigações fiscais, com destaque correto de impostos como ICMS, IPI, PIS e COFINS."
    },
    {
      icon: "fa-receipt",
      title: "Emissão de Nota Fiscal",
      description: "Emissão simplificada de NF-e e NFS-e com apenas alguns cliques."
    },
    {
      icon: "fa-cash-register",
      title: "NFCe",
      description: "Emissão automática de NFCe no PDV FortePlus, ideal para vendas rápidas no varejo."
    },
    {
      icon: "fa-money-check-alt",
      title: "Boleto Bancário",
      description: "Geração simples de boletos com integração CNAB para remessa e retorno bancários."
    },
    {
      icon: "fa-tasks",
      title: "Ordem de Serviço",
      description: "Controle de processos de serviços, gestão de produtos e serviços, e liberação parcial gerando NF-e."
    }
  ];

  const diferenciais = [
    {
      icon: "fa-sync",
      title: "Atualizado",
      description: "Sempre em dia com as novas legislações fiscais brasileiras, garantindo conforto e agilidade nas operações."
    },
    {
      icon: "fa-user-friends",
      title: "Interface Amigável",
      description: "Fácil entendimento e usabilidade, permitindo rápida adaptação de novos colaboradores."
    },
    {
      icon: "fa-shield-alt",
      title: "Segurança",
      description: "Correções e atualizações periódicas para melhorar o sistema e se adequar às necessidades do cliente."
    },
    {
      icon: "fa-tachometer-alt",
      title: "Eficiência",
      description: "Agiliza processos e tomada de decisões com telas objetivas e de fácil manipulação."
    }
  ];

  return (
    <div className="madeireira-container">
      <header className="madeireira-header">
        
        <div className="madeireira-titulo-div"><h1>Sistema para Madeireira e Lojas de Materiais de Construção</h1></div>
        <p>Controle completo para sua madeireira ou loja de materiais de construção com o FortePlus</p>
      </header>

      <section className="madeireira-hero">
        <div className="hero-content">
          <h2>Transforme sua gestão com o FortePlus</h2>
          <p>Soluções específicas para o setor madeireiro e de materiais de construção</p>
          <button className="cta-button" onClick={openModal}>Fale com um consultor</button>
        </div>
        <div className="hero-image">
          <img src="/images/madeireira-imagem.jpg" alt="Madeireira" />
        </div>
      </section>

      <section className="funcionalidades-section">
        <h2>Funcionalidades Principais</h2>
        <div className="funcionalidades-grid">
          {funcionalidades.map((item, index) => (
            <div key={index} className="funcionalidade-item">
              <i className={`fas ${item.icon}`}></i>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="fluxo-caixa-section">
        <div className="fluxo-caixa-content">
          <h2>Fluxo de Caixa</h2>
          <p>Uma ferramenta essencial para monitorar a saúde financeira da sua empresa. Analise períodos e contas/caixas com facilidade.</p>
          <a href="/blog-fluxo-caixa" className="link-button">Saiba mais sobre isso</a>
        </div>
        <div className="fluxo-caixa-image">
          <img src="/images/fluxo-caixa-madeireira.png" alt="Fluxo de Caixa" />
        </div>
      </section>

      <section className="manifesto-nfe-section">
        <h2>Manifesto de NFe</h2>
        <p>Realize o evento de manifestação do destinatário de forma prática e pontual, informando ao fisco sobre o andamento de operações de produtos e/ou serviços.</p>
      </section>

      <section className="diferenciais-section">
        <h2>Por que escolher o FortePlus?</h2>
        <div className="diferenciais-grid">
          {diferenciais.map((item, index) => (
            <div key={index} className="diferencial-item">
              <i className={`fas ${item.icon}`}></i>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="cta-section-madeireira">
        <h2 className="cta-section-madeireira-title">Pronto para otimizar sua madeireira?</h2>
        <p className="cta-section-madeireira-description">Entre em contato com nossos consultores comerciais e descubra como o FortePlus pode transformar seu negócio.</p>
        <button className="cta-button" onClick={openModal}>Solicite uma demonstração</button>
      </section>

      <ContatoModal isOpen={isModalOpen} onClose={closeModal} nomeTela="Madeireira" />
    </div>
  );
}