import React, { useState, useRef, useEffect } from "react";
import "./PlanosModal.css";
import UserDataForm from "./formulario";
import { color } from "framer-motion";

const planos = {
  mensal: [
    {
      nome: "Essencial",
      preco: 99.90,
      implantacao: 800.0,
      recursos: [
        "1 usuário incluído",
        "300MB de espaço",
        "Pedido de Venda",
        "Nota de Consumidor (NFC-e)",
        "Nota Fiscal Eletrônica (NFe)"
      ],
      observacoes: [
        "",
      ],
    },
    {
      nome: "Plus",
      preco: 175.90,
      implantacao: 1200.0,
      recursos: [
        "2 usuários incluídos",
        "500MB de espaço",
        "Pedido de Venda",
        "Nota de Consumidor (NFC-e)",
        "Nota Fiscal Eletrônica (NFe)",
        "Controle de Estoque",
        "Entrada de Documentos Fiscais",
        "Permissões de Acesso",
        "Suporte por telefone",
        "Dashboard de vendas"
      ],
      observacoes: [
        "",
      ],
    },
    {
      nome: "Pro",
      preco: 290.0,
      implantacao: 2000.0,
      recursos: [
        "4 usuários incluídos",
        "900MB de espaço",
        "Pedido de Venda",
        "Nota de Consumidor (NFC-e)",
        "Nota Fiscal Eletrônica (NFe)",
        
        "Nota Fiscal de Serviço (NFS-e)",
        "Controle de Estoque",
        "Entrada de Documentos Fiscais",
        "Permissões de Acesso",
        "Suporte por telefone",
        "Dashboard de vendas",
        "Processos de compras",
        "Portal do Fornecedor",
        "Finanças completo",
        "Integrações bancárias automáticas"
      ],
      observacoes: [
        "",
      ],
      destaque: true
    },
    {
      nome: "Sob Medida",
      preco: "Consulte",
      implantacao: "Personalizada",
      recursos: [
        "Todas as funcionalidades do plano Pro",
        "Usuários definidos conforme necessidade",
        "2000MB de espaço",
        "Consultoria Personalizada",
        "Gestão da Produção",
        "Controle da Qualidade",
        "Dashboard de Produção",
        "Gestão de Contratos",
        "Controle de Logistica",
        "Rastreabilidade",
        "Integração com ecommerce",
        "Gestão de serviços",
        "Treinamento personalizado"
      ],
      observacoes: [
        "Plano customizado de acordo com as necessidades específicas da sua empresa",
        "Entre em contato para uma avaliação detalhada e orçamento personalizado",
      ],
    },
  ],
  anual: [
    {
      nome: "Essencial",
      preco: 959.04,
      precoMensal: 79.92,
      economia: "R$ 239,76",
      desconto: "20%",
      implantacao: 800.0,
      recursos: [
        "1 usuário incluído",
        "300MB de espaço",
        "Pedido de Venda",
        "Nota de Consumidor (NFC-e)",
        "Nota Fiscal Eletrônica (NFe)"
      ],
      observacoes: [
        "",
      ],
      anual: true
    },
    {
      nome: "Plus",
      preco: 1688.64,
      precoMensal: 140.72,
      economia: "R$ 422,16",
      desconto: "20%",
      implantacao: 1200.0,
      recursos: [
        "2 usuários incluídos",
        "500MB de espaço",
        "Pedido de Venda",
        "Nota de Consumidor (NFC-e)",
        "Nota Fiscal Eletrônica (NFe)",
        "Controle de Estoque",
        "Entrada de Documentos Fiscais",
        "Permissões de Acesso",
        "Suporte por telefone",
        "Dashboard de vendas"
      ],
      observacoes: [
        "",
      ],
      anual: true
    },
    {
      nome: "Pro",
      preco: 2784.0,
      precoMensal: 232.0,
      economia: "R$ 696,00",
      desconto: "20%",
      implantacao: 2000.0,
      recursos: [
        "4 usuários incluídos",
        "900MB de espaço",
        "Pedido de Venda",
        "Nota de Consumidor (NFC-e)",
        "Nota Fiscal Eletrônica (NFe)",
        "Nota Fiscal de Serviço (NFS-e)",
        "Controle de Estoque",
        "Entrada de Documentos Fiscais",
        "Permissões de Acesso",
        "Suporte por telefone",
        "Dashboard de vendas",
        "Processos de compras",
        "Portal do Fornecedor",
        "Finanças completo",
        "Integrações bancárias automáticas"
      ],
      observacoes: [
        "",
      ],
      destaque: true,
      anual: true
    },
  ],
};

export default function PlanosModal({ isOpen, onClose }) {
  const [tipoPlano, setTipoPlano] = useState("mensal");
  const [showForm, setShowForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (!isOpen) {
      setShowForm(false);
      setSelectedPlan(null);
    }
  }, [isOpen]);

  const handlePlanSelection = (plano) => {
    setSelectedPlan(plano);
    setShowForm(true);
    modalRef.current.scrollTo(0, 0);
  };

  const handleUserDataSubmit = (userData) => {
    console.log("Dados do usuário:", userData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        
        {!showForm ? (
          <>
            <h2 className="modal-title">
              Potencialize Seu Negócio com Nossos Planos
            </h2>
            <p className="modal-subtitle">
              Escolha a solução ideal e economize até 20% nos planos anuais
            </p>
            <div className="tabs">
              <button
                className={`tab ${tipoPlano === "mensal" ? "active" : ""}`}
                onClick={() => setTipoPlano("mensal")}
              >
                Mensal
              </button>
              <button
                className={`tab ${tipoPlano === "anual" ? "active" : ""}`}
                onClick={() => setTipoPlano("anual")}
              >
                Anual
              </button>
            </div>
            <div className="planos-container">
              {planos[tipoPlano].map((plano, index) => (
                <div
                  key={index}
                  className={`plano-card ${plano.destaque ? "destaque" : ""}`}
                >
                  <div className="plano-header">
                    <h3>{plano.nome}</h3>
                    {plano.destaque && (
                      <span className="destaque-tag">Mais Popular</span>
                    )}
                  </div>
                  {tipoPlano === "mensal" ? (
                    <p className="preco">
                      R$
                      {plano.preco}
                      <span>/mês</span>
                    </p>
                  ) : (
                    <>
                      <p className="preco">
                        R$
                        {plano.precoMensal}
                        <span>/mês</span>
                      </p>
                      <p className="preco-anual">Total: R$ {plano.preco}/ano</p>
                      <p className="economia">
                        Economize {plano.desconto} por ano!
                      </p>
                      <p className="desconto">{plano.economia} de desconto</p>
                    </>
                  )}
                  <p className="implantacao">
                    Implantação: R$ {plano.implantacao} <i className="fas fa-info-circle" title="Custo único para a configuração e integração do sistema"></i>
                  </p>

                  <ul>
                    {plano.recursos.map((recurso, idx) => (
                      <li className="lista-recursos" key={idx}>
                        {recurso}
                      </li>
                    ))}
                  </ul>
                  <div className="observacoes">
                    {plano.observacoes.map((obs, idx) => (
                      <p key={idx} className="observacao">
                        {obs}
                      </p>
                    ))}
                  </div>
                  <button
                    className="escolher-plano"
                    onClick={() => handlePlanSelection(plano)}
                  >
                    Começar Agora
                  </button>
                </div>
              ))}
            </div>
          </>
        ) : (
          <UserDataForm
            onSubmit={handleUserDataSubmit}
            onCancel={() => {
              setShowForm(false);
              setSelectedPlan(null);
            }}
            selectedPlan={selectedPlan}
            tipoPlano={tipoPlano}
          />
        )}
      </div>
    </div>
  );
}
