import React, { useState } from "react";
import "./servicos.css";
import ContatoModal from "../../../componentes/ContatoModal";

export default function Servicos() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const servicosDestaque = [
    { icon: "fa-file-contract", title: "Gestão de Contratos" },
    { icon: "fa-clipboard-list", title: "Ordem de Serviço" },
    { icon: "fa-file-invoice-dollar", title: "Orçamentos" },
    { icon: "fa-calendar-alt", title: "Controle de Agenda" },
  ];

  const beneficios = [
    { icon: "fa-rocket", title: "Aumento da produtividade", description: "Otimize processos e aumente a eficiência da sua equipe." },
    { icon: "fa-chart-line", title: "Melhor controle financeiro", description: "Tenha visão clara das suas finanças e tome decisões informadas." },
    { icon: "fa-smile", title: "Satisfação do cliente", description: "Melhore a experiência do cliente com atendimento ágil e eficaz." },
    { icon: "fa-check-circle", title: "Redução de erros operacionais", description: "Minimize erros com processos automatizados e integrados." },
    { icon: "fa-lightbulb", title: "Tomada de decisão baseada em dados", description: "Use insights poderosos para guiar o crescimento do seu negócio." },
    { icon: "fa-shield-alt", title: "Conformidade fiscal e tributária", description: "Mantenha-se em dia com obrigações legais e evite problemas." },
  ];

  return (
    <div className="servicos-container">
      <header className="servicos-header">
        <div className="servicos-header-content">
          <h1>Transforme sua Prestadora de Serviços</h1>
          <p>Gestão eficiente e controle total com o FortePlus</p>
          <button className="cta-button" onClick={openModal}>Solicite uma demonstração</button>
        </div>
      </header>

      <section className="servicos-destaque">
        <h2>Soluções Completas para sua Empresa</h2>
        <div className="servicos-destaque-grid">
          {servicosDestaque.map((servico, index) => (
            <div key={index} className="servico-item">
              <i className={`fas ${servico.icon}`}></i>
              <h3>{servico.title}</h3>
            </div>
          ))}
        </div>
      </section>

      <section className="servicos-beneficios">
        <h2>Benefícios do FortePlus</h2>
        <div className="beneficios-grid">
          {beneficios.map((beneficio, index) => (
            <div key={index} className="beneficio-item">
              <div className="beneficio-icon">
                <i className={`fas ${beneficio.icon}`}></i>
              </div>
              <div className="beneficio-content">
                <h3>{beneficio.title}</h3>
                <p>{beneficio.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="beneficios-cta">
          <p>Transforme sua prestadora de serviços com esses benefícios</p>
          <button className="cta-button" onClick={openModal}>Solicitar demonstração</button>
        </div>
      </section>

      <section className="servicos-recursos">
        <h2>Recursos Essenciais para sua Prestadora de Serviços</h2>
        <div className="recursos-grid">
          <div className="recurso-item">
            <i className="fas fa-tasks"></i>
            <h3>Gestão de Projetos</h3>
            <p>Acompanhe o progresso de seus projetos e mantenha sua equipe alinhada.</p>
          </div>
          <div className="recurso-item">
            <i className="fas fa-chart-line"></i>
            <h3>Relatórios e Dashboards</h3>
            <p>Visualize o desempenho do seu negócio com relatórios personalizados.</p>
          </div>
          <div className="recurso-item">
            <i className="fas fa-mobile-alt"></i>
            <h3>Acesso Mobile</h3>
            <p>Gerencie sua empresa de qualquer lugar, a qualquer momento.</p>
          </div>
          <div className="recurso-item">
            <i className="fas fa-lock"></i>
            <h3>Segurança de Dados</h3>
            <p>Proteja as informações da sua empresa e de seus clientes.</p>
          </div>
        </div>
      </section>
      <section className="servicos-depoimento">
        <blockquote>
          "O sucesso não é o resultado de um único fator, mas sim de uma combinação de trabalho duro, oportunidades e resiliência."
        </blockquote>
        <cite>- Bill Gates, Cofundador da Microsoft</cite>
      </section>
      <section className="servicos-cta">
        <h2>Pronto para Elevar sua Prestadora de Serviços?</h2>
        <p>Descubra como o FortePlus pode impulsionar seu negócio.</p>
        <button className="cta-button" onClick={openModal}>Agende uma Demonstração Gratuita</button>
      </section>

      <ContatoModal isOpen={isModalOpen} onClose={closeModal} nomeTela="Serviços" />
    </div>
  );
}