import React, { useState } from "react";
import "./footer.css";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import JornadaInicio from "../JornadaInicio";
import ChatComponent from "componentes/chatbot";



export function Footer() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  }
  const [isJornadaOpen, setIsJornadaOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showChat, setShowChat] = useState(false);

  const handleComecarClick = () => {
    setIsJornadaOpen(true);
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const handleJornadaComplete = (data) => {
    setUserData(data);
    setIsJornadaOpen(false);
    setShowChat(true);
  };

  
  return (
    <footer className="smart-footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/logo-branca.png" alt="Forteplus Logo" />
          <p>
            FortePlus Sistemas, impulsionando seu negócio para o próximo nível,
            transformando complexidade em simplicidade e desafios em conquistas.
          </p>
          <div className="footer-social">
            <a
              href="https://www.facebook.com/forteplussistemas/"
              aria-label="Facebook"
            >
              <FaFacebook />
            </a>
            <a
              href="https://x.com/forteplussistemas"
              aria-label="X"
            >
              <FaTwitter />
            </a>
            <a href="https://instagram.com/forteplus" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a
              href="https://br.linkedin.com/company/forteplus-sistemas"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
        <div className="footer-links-container">
          <div className="footer-links">
            <h3 className="footer-links-title">Serviços</h3>
            <ul>
              <li>
                <Link to="/funcionalidades" onClick={handleClick}>Funcionalidades</Link>
              </li>
              <li>
                <Link to="/solucoes" onClick={handleClick}>Soluções</Link>
              </li>
              <li>
                <a href="#contact" onClick={handleClick}>Contato</a>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h3 className="footer-links-title">Empresa</h3>
            <ul>
              <li>
                <Link to="/quem-somos" onClick={handleClick}>Sobre nós</Link>
              </li>
              <li>
                <Link to="/blog" onClick={handleClick}>Blog</Link>
              </li>
              <li>
                <p onClick={handleComecarClick} className="comercarFooter">Começar</p>
              </li>
              
            </ul>
          </div>
          <div className="footer-links">
            <h3 className="footer-links-title">Contato</h3>
            <ul>
              <li>
                <a href="mailto:contato@forteplus.com.br">
                  contato@forteplus.com.br
                </a>
              </li>
              <li>
                <a href="tel:+553135821410">+55 31 3582-1410</a>
              </li>
              <li>
                <address>
                  <a
                    href="https://maps.app.goo.gl/qBiBX5QJb4W8yw4D6"
                    id="endereco"
                  >
                    Rua Queluzita, 34
                    <br />
                    Belo Horizonte - MG, 31170-679
                  </a>
                </address>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} FortePlus Sistemas. Todos os
          direitos reservados.
        </p>
        <a href="/politica-de-privacidade" className="politica-footer" name="Politica de privacidade">Política de Privacidade</a>
      </div>
      <JornadaInicio
        isOpen={isJornadaOpen}
        onClose={() => setIsJornadaOpen(false)}
        onComplete={handleJornadaComplete}
      />
      {showChat && (
        <div className="chat-wrapper">
          <ChatComponent initialData={userData} />
          <button onClick={() => setShowChat(false)} className="close-chat">
            Fechar Chat
          </button>
        </div>
      )}
    </footer>
    
  );
}
