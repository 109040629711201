import "./card.css";
import React from "react";
export function CardSimples({ descricao, imagem }) {
  return (
    <div className="card-simples">
      <img src={imagem} alt="icone" />
      <p>{descricao}</p>
    </div>
  );
}
