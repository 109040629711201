import "./dados.css";
import React from "react";
import CountUp from "react-countup";

export default function Dados(props) {
  const numberValue = parseInt(props.numero.replace(/\D/g, ""));

  return (
    <div className="dados-home-item" style={{ flexDirection: props.direction }}>
      <div className="dados-home-img">
        <img src={props.img} alt="icone" />
      </div>
      <div className="dados-home-info">
        <p className="dados-home-numero">
          +<CountUp end={numberValue} duration={10} separator="." />
          {props.numero.includes("Mil") ? "Mil" : ""}
        </p>
        <p className="dados-home-descricao">{props.descricao}</p>
      </div>
    </div>
  );
}
