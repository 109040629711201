import React /* , { useState, useEffect } */ from "react";
/* import axios from 'axios'; */
/* import BlogPost from './BlogPost';

import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import { Height } from '@mui/icons-material';
 */
import { Container } from "@mui/material";
import './blog.css';
export default function Blog() {
  /*   const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/posts');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchPosts();
  }, []);
 */
  return (
    <Container maxWidth="md" className="blog-container" style={
      {display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center",minHeight:"100vh",userSelect:"none",}
    }>
      {/*       <Typography variant="h2" gutterBottom>Blog FortePlus</Typography>
      <Button component={Link} to="/create-post" variant="contained" color="primary" className="create-post-link">
        Criar Nova Postagem
      </Button>
      <div className="blog-posts">
        {posts.length > 0 ? (
          posts.map(post => <BlogPost key={post.id} post={post} />)
        ) : (
          <Typography variant="body1">Nenhuma postagem encontrada.</Typography>
        )}
      </div> */}
      <img
        src="./images/blog-builder.png"
        style={{
          margin: "0 auto",
          width: "50%",
        }}
      />
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Nosso blog está em desenvolvimento. Volte mais tarde!
      </h1>
    </Container>
  );
}
