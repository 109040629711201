import React, { useState, useEffect, useCallback, useRef } from "react"; // Adicione useRef
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import {
  FaInfoCircle,
  FaCheckCircle,
  FaSpinner,
  FaRocket,
  FaShieldAlt,
  FaStar,
  FaWhatsapp,
  FaQuoteLeft,
} from "react-icons/fa";
import emailjs from "@emailjs/browser";

import "../PlanosModal.css";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaBriefcase,
  FaUsers,
  FaComments,
} from "react-icons/fa";

const UserDataForm = ({ onSubmit, onCancel, selectedPlan, tipoPlano }) => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    empresa: "",
    cargo: "",
    numeroFuncionarios: "",
    comentarios: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [adicionais] = useState({
    certificadoDigital: {
      nome: "Certificado Digital",
      valor: 100,
      selecionado: false,
    },
  });
  const [showBeneficios, setShowBeneficios] = useState(false);
  const timeoutRef = useRef(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "email") {
      formattedValue = value.toLowerCase();
    }

    setFormData({ ...formData, [name]: formattedValue });
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome é obrigatório";
    if (!validateEmail(formData.email)) newErrors.email = "E-mail inválido";
    const phoneDigits = formData.telefone.replace(/\D/g, '').length;
    if (phoneDigits !== 10 && phoneDigits !== 11)
      newErrors.telefone = "Telefone deve ter 10 ou 11 dígitos";
    if (!formData.empresa.trim()) newErrors.empresa = "Empresa é obrigatória";
    if (!formData.cargo.trim()) newErrors.cargo = "Cargo é obrigatório";
    if (!formData.numeroFuncionarios)
      newErrors.numeroFuncionarios = "Número de functionários é obrigatório";
    if (!captchaValue) newErrors.captcha = "Por favor, complete o CAPTCHA";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    setErrorMessage("");

    const bodyData = {
      nome: formData.nome,
      email: formData.email,
      telefone: formData.telefone,
      empresa: formData.empresa,
      planoNome: selectedPlan.nome,
      tipoPlano: tipoPlano,
      preco: calcularTotal(),
      comentarios: formData.comentarios,
    };

    try {
      const response = await emailjs.send(
        "service_2f9oib6",
        "template_rz7t3fe",
        bodyData,
        "x5I3X-lyJcSzH7RfK"
      );

      if (response.status === 200) {
        console.log("Email enviado com sucesso, abrindo modal...");
        setShowSuccessModal(true);
      } else {
        throw new Error("Erro ao enviar e-mail.");
      }
    } catch (error) {
      console.error("Erro ao enviar e-mail:", error);
      alert("Ocorreu um erro ao enviar o e-mail: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    onSubmit(formData);
  };

  const calcularTotal = useCallback(() => {
    if (selectedPlan.nome !== "Sob Medida") {
      let total = selectedPlan.preco; // Começa com o preço do plano selecionado
      Object.values(adicionais).forEach((adicional) => {
        if (adicional.selecionado) {
          total += adicional.valor; // Adiciona o valor dos adicionais selecionados
        }
      });
      return total; // Retorna o total correto
    }
  }, [adicionais, selectedPlan]);

  const AtualizarValor = useCallback(() => {
    if (selectedPlan.nome !== "Sob Medida") {
      const total = calcularTotal();
      document.querySelector(".plan-price").innerHTML = `Plano: R$ ${total}`;
      if (selectedPlan.anual) {
        const planPriceElement = document.createElement("p");
        planPriceElement.classList.add("parcelasAnual");
        planPriceElement.textContent = `Economia de: ${selectedPlan.economia} por ano!`;
        document.querySelector(".plan-price").appendChild(planPriceElement);
      }

      const planPriceElement = document.createElement("p");
      planPriceElement.classList.add("implantacaoPreco");
      planPriceElement.textContent = `Implantacao: R$ ${selectedPlan.implantacao}`;
      document.querySelector(".plan-price").appendChild(planPriceElement);
    } else {
      document.querySelector(
        ".plan-price"
      ).innerHTML = `Plano: R$ ${selectedPlan.preco}`;
    }
  }, [
    calcularTotal,
    selectedPlan.anual,
    selectedPlan.implantacao,
    selectedPlan.nome,
    selectedPlan.preco,
    selectedPlan.economia,
  ]);

  useEffect(() => {
    AtualizarValor();
  }, [AtualizarValor]);

  useEffect(() => {
    AtualizarValor();
  }, [adicionais, AtualizarValor]);

  console.log("Estado do modal:", showSuccessModal);

  return (
    <div className="form-wrapper">
      <div className="user-data-form-container">
        <div className="form-content">
          <div className="form-left">
            <h3>Estamos quase lá!</h3>
            <p className="form-subtitle">
              Preencha seus dados para finalizar a contratação do plano{" "}
              <b>
                {selectedPlan.nome} ({tipoPlano})
              </b>
            </p>
            <form onSubmit={handleSubmit} className="user-data-form">
              <div className="form-row">
                <div className="form-group">
                  <FaUser className="input-icon" />
                  <input
                    type="text"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    placeholder="Nome completo"
                    required
                  />
                  {errors.nome && (
                    <span className="error-message">{errors.nome}</span>
                  )}
                </div>
                <div className="form-group">
                  <FaEnvelope className="input-icon" />
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="E-mail"
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    title="Por favor, insira um endereço de e-mail válido"
                  />
                  {errors.email && (
                    <span className="error-message">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <FaPhone className="input-icon" />
                  <InputMask
                    mask={formData.telefone.replace(/\D/g, '').length >= 11 ? "(99) 99999-9999" : "(99) 99999-9999"}
                    maskChar={null}
                    type="tel"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleChange}
                    placeholder="Telefone"
                    required
                  />
                  {errors.telefone && (
                    <span className="error-message">{errors.telefone}</span>
                  )}
                </div>
                <div className="form-group">
                  <FaBuilding className="input-icon" />
                  <input
                    type="text"
                    name="empresa"
                    value={formData.empresa}
                    onChange={handleChange}
                    placeholder="Nome da empresa"
                    required
                  />
                  {errors.empresa && (
                    <span className="error-message">{errors.empresa}</span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <FaBriefcase className="input-icon" />
                  <input
                    type="text"
                    name="cargo"
                    value={formData.cargo}
                    onChange={handleChange}
                    placeholder="Cargo"
                    required
                  />
                  {errors.cargo && (
                    <span className="error-message">{errors.cargo}</span>
                  )}
                </div>
                <div className="form-group">
                  <FaUsers className="input-icon" />
                  <select
                    required
                    name="numeroFuncionarios"
                    value={formData.numeroFuncionarios}
                    onChange={handleChange}
                  >
                    <option value="">Número de funcionários</option>
                    <option value="1-10">1-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-200">51-200</option>
                    <option value="201+">201+</option>
                  </select>
                  {errors.numeroFuncionarios && (
                    <span className="error-message">
                      {errors.numeroFuncionarios}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <FaComments className="input-icon" />
                <textarea
                  name="comentarios"
                  style={{
                    width: "calc(100% - 80px)",
                    padding: "20px 40px",
                  }}
                  value={formData.comentarios}
                  onChange={handleChange}
                  placeholder="Comentários adicionais ou requisitos específicos"
                  rows="3"
                ></textarea>
              </div>
              <div className="form-info">
                <p>
                  Ao enviar, você concorda com nossos{" "}
                  <a
                    href="/termos-de-servico"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos de Serviço
                  </a>{" "}
                  e{" "}
                  <a
                    href="/politica-de-privacidade"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de Privacidade
                  </a>
                  .
                </p>
              </div>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6LfLv1AqAAAAAMs0LMmaQAbehBrP-SgVYHDiq9ov"
                  onChange={(value) => setCaptchaValue(value)}
                />
                {errors.captcha && (
                  <span className="error-message">{errors.captcha}</span>
                )}
              </div>
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className="fa-spin" /> Enviando...
                    </>
                  ) : (
                    "Assinar Plano"
                  )}
                </button>
                <button
                  type="button"
                  onClick={onCancel}
                  className="btn-secondary"
                  disabled={isLoading}
                >
                  Voltar
                </button>
              </div>
            </form>
          </div>
          <div className="form-right">
            <div className="plan-summary">
              <h4>Resumo do Plano</h4>
              <p className="plan-name">
                <b>Plano {selectedPlan.nome}</b>
              </p>
              <p className="plan-type">
                Você escolheu o <u>pagamento {tipoPlano}</u>
              </p>

              <div className="adicionalItem">
                <div className="recomendado">RECOMENDADO</div>
                <p className="nomeAdicional">
                  Certificado Digital
                  <FaInfoCircle
                    className="infoItemAdicional"
                    onMouseEnter={() => {
                      clearTimeout(timeoutRef.current); // Limpa o timeout
                      setShowBeneficios(true);
                    }}
                    onMouseLeave={() => {
                      timeoutRef.current = setTimeout(
                        () => setShowBeneficios(false),
                        200
                      ); // Armazena o timeout na referência
                    }}
                  />
                </p>

                {showBeneficios && (
                  <div
                    className="beneficioAdicional"
                    style={{ padding: "20px" }}
                    onMouseEnter={() => {
                      clearTimeout(timeoutRef.current); // Cancela o timeout ao entrar na div
                    }}
                    onMouseLeave={() => {
                      timeoutRef.current = setTimeout(
                        () => setShowBeneficios(false),
                        200
                      ); // Armazena o timeout na referência
                    }}
                  >
                    <strong>Benefícios do Certificado Digital:</strong>
                    <ul style={{ listStyle: "none", padding: "0px" }}>
                      <li>
                        ✔️ Evite a busca por fornecedores externos e
                        simplificando o processo de compra.
                      </li>
                      <li>
                        ✔️ Garantimos que o certificado digital funcione
                        perfeitamente com o sistema, facilitando a configuração
                        e uso imediato.
                      </li>
                      <li>
                        ✔️ Ao comprar o certificado junto ao plano, você tem uma
                        solução completa, economizando tempo e dinheiro.
                      </li>
                      <li>
                        ✔️ Beneficie-se de atualizações automáticas e manutenção
                        do certificado.
                      </li>
                    </ul>
                  </div>
                )}
                <p>
                  Certificado <b>A1 e A3</b> -
                  <em>Agilidade, Economia e Segurança!</em>
                </p>
                <button
                  type="button"
                  className="btn-item-certificado" // Classe para estilização do botão
                  onClick={() => {
                    // Lógica para redirecionar para a tela de informações do certificado digital em outra guia
                    window.open(
                      "https://certcontrol.com.br/web/venda-rapida/vd0tp9823tk35cb9586a2",
                      "_blank"
                    ); // Substitua pela URL correta
                  }}
                >
                  Adquira seu certificado
                </button>
              </div>

              <p className="plan-price">
                {tipoPlano === "anual" ? (
                  <>
                    <span>Total: R$ {calcularTotal()}</span>{" "}
                    {/* Exibe o valor total com "Total:" */}
                    <br />
                    <span className="parcelasAnual">
                      12 parcelas de R$ {(calcularTotal() / 12).toFixed(2)}{" "}
                      {/* Exibe o valor mensal correto */}
                    </span>
                  </>
                ) : (
                  calcularTotal() // Exibe o total para planos mensais
                )}
              </p>
              {tipoPlano === "anual" && (
                <p className="plan-savings">
                  Economia de <b>{selectedPlan.desconto}</b> por ano
                </p>
              )}
              <ul className="plan-features">
                {selectedPlan.recursos.map((recurso, index) => (
                  <li key={index}>
                    <FaCheckCircle /> {recurso}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {showSuccessModal && (
        <div className="success-modal-overlay">
          <div className="success-modal">
            <div className="success-icon">
              <FaRocket className="rocket-icon" />
            </div>

            <h2>🎉 Parabéns, você assinou o plano {selectedPlan.nome}!</h2>

            <p className="welcome-message">
              <strong>{formData.nome}</strong>, você deu o primeiro passo para transformar a gestão da{" "}
              <strong>{formData.empresa}</strong>!
            </p>
            <p className="welcome-message">
              Fique tranquilo, agora é por nossa conta. Já estamos cuidando de tudo!
            </p>

            <div className="success-steps">
              <h3>✨ Sua Jornada de Sucesso:</h3>
              <div className="timeline">
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <FaCheckCircle />
                  </div>
                  <div className="timeline-content">
                    <h4>Preparação Exclusiva</h4>
                    <p>
                      Nossa equipe profissional já está configurando seu
                      ambiente personalizado!
                    </p>
                  </div>
                </div>

                <div className="timeline-item">
                  <div className="timeline-icon">
                    <FaEnvelope />
                  </div>
                  <div className="timeline-content">
                    <h4>Explicações Detalhadas</h4>
                    <p>
                      Entraremos em contato pelo telefone: {formData.telefone}{" "}
                      para esclarecer todas as dúvidas!
                    </p>
                  </div>
                </div>

                <div className="timeline-item">
                  <div className="timeline-icon">
                    <FaStar />
                  </div>
                  <div className="timeline-content">
                    <h4>Experiência Extraordinária</h4>
                    <p>
                      A partir de agora você terá acesso a uma experiência de
                      gestão inovadora!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button className="success-modal-button" onClick={handleCloseModal}>
              Continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDataForm;
