import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaComments,
  FaSpinner,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { PatternFormat } from "react-number-format";
import ReCAPTCHA from "react-google-recaptcha";
import "./contatomodal.css";

const ContatoModal = ({ isOpen, onClose, nomeTela }) => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    empresa: "",
    mensagem: "",
    tela: nomeTela,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome é obrigatório";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "E-mail inválido";
    if (!/^\(\d{2}\)\s\d{5}-\d{4}$/.test(formData.telefone)) newErrors.telefone = "Telefone inválido";
    if (!formData.empresa.trim()) newErrors.empresa = "Empresa é obrigatória";
    if (!formData.mensagem.trim())
      newErrors.mensagem = "Mensagem é obrigatória";
    if (!captchaValue) newErrors.captcha = "Por favor, complete o CAPTCHA";
    const suspiciousWords = ["spam", "viagra", "casino"];
    if (
      suspiciousWords.some((word) =>
        formData.mensagem.toLowerCase().includes(word)
      )
    ) {
      newErrors.mensagem = "Sua mensagem contém conteúdo suspeito";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    setErrorMessage("");
    setStatus("Enviando...");

    emailjs
      .send(
        "service_2f9oib6",
        "template_t3fvu6c",
        {
          nome: formData.nome,
          email: formData.email,
          telefone: formData.telefone,
          empresa: formData.empresa,
          mensagem: formData.mensagem,
          tela: formData.tela,
        },
        "x5I3X-lyJcSzH7RfK"
      )
      .then(
        (result) => {
          setStatus("E-mail enviado com sucesso!");
          setSubmitSuccess(true);
          setTimeout(() => {
            onClose();
            setSubmitSuccess(false);
            setFormData({
              nome: "",
              email: "",
              telefone: "",
              empresa: "",
              mensagem: "",
              tela: nomeTela,
            });
            setCaptchaValue(null);
          }, 3000);
        },
        (error) => {
          setStatus("Erro ao enviar e-mail: " + error.text);
          console.error("Erro ao enviar e-mail:", error);
          setErrorMessage(error.text);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="contato-modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="contato-modal-content"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
          >
            {submitSuccess ? (
              <div className="contato-modal-success">
                <h2 className="contato-modal-title">Fale com um consultor</h2>
                <p>Obrigado! Entraremos em contato em breve.</p>
              </div>
            ) : errorMessage ? (
              <div className="contato-modal-error">
                <h2 className="contato-modal-title">Ops! Houve um problema.</h2>
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage("")} className="contato-modal-btn-secondary">
                  Tentar novamente
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="contato-modal-form">
                <h3>Entraremos em contato</h3>
                <div className="contato-modal-form-group">
                  <FaUser className="contato-modal-input-icon" />
                  <input
                    type="text"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    placeholder="Nome completo"
                    required
                    className={`contato-modal-input ${errors.nome ? "error" : ""}`}
                  />
                  {errors.nome && (
                    <span className="error-message">{errors.nome}</span>
                  )}
                </div>
                <div className="contato-modal-form-group">
                  <FaEnvelope className="contato-modal-input-icon" />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="E-mail"
                    required
                    className={`contato-modal-input ${errors.email ? "error" : ""}`}
                  />
                  {errors.email && (
                    <span className="error-message">{errors.email}</span>
                  )}
                </div>
                <div className="contato-modal-form-group">
                  <FaPhone className="contato-modal-input-icon" />
                  <PatternFormat
                    format="(##) #####-####"
                    mask="_"
                    type="tel"
                    name="telefone"
                    value={formData.telefone}
                    onValueChange={(values) => {
                      const { formattedValue } = values;
                      setFormData(prevData => ({ ...prevData, telefone: formattedValue }));
                    }}
                    placeholder="Telefone"
                    required
                    className={`contato-modal-input ${errors.telefone ? "error" : ""}`}
                  />
                  {errors.telefone && (
                    <span className="error-message">{errors.telefone}</span>
                  )}
                </div>
                <div className="contato-modal-form-group">
                  <FaBuilding className="contato-modal-input-icon" />
                  <input
                    type="text"
                    name="empresa"
                    value={formData.empresa}
                    onChange={handleChange}
                    placeholder="Nome da empresa"
                    required
                    className={`contato-modal-input ${errors.empresa ? "error" : ""}`}
                  />
                  {errors.empresa && (
                    <span className="error-message">{errors.empresa}</span>
                  )}
                </div>
                <div className="contato-modal-form-group">
                  <FaComments className="contato-modal-input-icon" />
                  <textarea
                    name="mensagem"
                    value={formData.mensagem}
                    onChange={handleChange}
                    placeholder="Sua mensagem"
                    rows="3"
                    required
                    className={`contato-modal-textarea ${errors.mensagem ? "error" : ""}`}
                  ></textarea>
                  {errors.mensagem && (
                    <span className="error-message">{errors.mensagem}</span>
                  )}
                </div>
                <div className="contato-modal-form-group">
                  <ReCAPTCHA
                    sitekey="6LfLv1AqAAAAAMs0LMmaQAbehBrP-SgVYHDiq9ov"
                    onChange={(value) => setCaptchaValue(value)}
                  />
                  {errors.captcha && (
                    <span className="error-message">{errors.captcha}</span>
                  )}
                </div>
                <div className="contato-modal-form-buttons">
                  <button
                    type="submit"
                    className="contato-modal-btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <FaSpinner className="contato-modal-fa-spin" />
                        Enviando...
                      </>
                    ) : (
                      "Enviar"
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="contato-modal-btn-secondary"
                    disabled={isLoading}
                  >
                    Cancelar
                  </button>
                </div>
                {status && <p>{status}</p>}
              </form>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ContatoModal;