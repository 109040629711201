import React, { useState } from "react";
import { Helmet } from "react-helmet";

import "./QuemSomos.css";
import ReCAPTCHA from "react-google-recaptcha";

const VALORES = [
  "Foco",
  "Inovação",
  "Resultado",
  "Respeito",
  "Confiança",
  "Lealdade",
  "Transparência",
  "Agilidade",
  "Integridade",
  "Responsabilidade",
  "Comprometimento",
  "Ética",
];
export default function QuemSomos() {
  const [captchaValue, setCaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    position: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
        alert("Por favor, complete o CAPTCHA antes de enviar.");
        return;
    }

    try {
        const data = {
            to: "lucas.vilaca@forteplus.com.br",
            subject: `${formData.name} - Nova candidatura - FortePlus`,
            formData,
            captcha: captchaValue,
            emailType: "candidatura",
        };

        console.log("Enviando requisição para:", "https://botemail.forteplus.com.br/api/send-email");
        console.log("Dados:", data);

        const response = await fetch("https://botemail.forteplus.com.br/api/send-email", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data)
        });

        // Log da resposta bruta
        const responseText = await response.text();
        console.log("Resposta bruta:", responseText);

        // Tentar fazer parse do JSON apenas se houver conteúdo
        let responseData;
        try {
            responseData = responseText ? JSON.parse(responseText) : {};
        } catch (e) {
            console.warn("Resposta não é JSON válido:", responseText);
            throw new Error("Resposta inválida do servidor");
        }

        if (!response.ok) {
            throw new Error(responseData.message || `Erro ${response.status}`);
        }

        alert("Obrigado pelo seu interesse! Entraremos em contato em breve.");
        setFormData({ name: "", email: "", position: "", message: "" });
    } catch (error) {
        console.error("Erro completo:", error);
        console.error("Stack trace:", error.stack);
        alert(`Ocorreu um erro ao enviar sua candidatura. Por favor, tente novamente mais tarde.`);
    }
  };


  return (
    <div className="quem-somos">
      <Helmet>
        <title>Quem Somos - FortePlus Sistemas</title>
        <meta
          name="description"
          content="Conheça a FortePlus Sistemas, especialista em desenvolvimento de software ERP para empresas em crescimento. Descubra nossos valores e diferenciais."
        />
        <meta
          name="keywords"
          content="FortePlus Sistemas, ERP inovador, software de gestão empresarial, soluções personalizadas, eficiência operacional, crescimento empresarial, tecnologia avançada, suporte especializado, implantação rápida, melhor custo-benefício"
        />
        <meta property="og:title" content="Quem Somos - FortePlus Sistemas" />
        <meta
          property="og:description"
          content="Especialistas em ERP para empresas em crescimento. Conheça nossos valores e diferenciais."
        />
        <meta
          property="og:image"
          content="https://www.forteplus.com.br/images/og-quem-somos.png"
        />
        <meta
          property="og:url"
          content="https://www.forteplus.com.br/quem-somos"
        />
        <link rel="canonical" href="https://www.forteplus.com.br/quem-somos" />
      </Helmet>

      <div className="quem-somos-container">
        <div className="quem-somos-container-texto">
          <h1 className="titulo-quem-somos">Quem somos?</h1>
          <p className="texto-quem-somos">
            Somos uma empresa especializada no desenvolvimento de software ERP,
            focada em fornecer soluções para empresas em crescimento, desde
            startups até grandes organizações. Aliamos tecnologia de ponta com
            uma abordagem flexível para atender às necessidades de cada cliente.
            Queremos simplificar a gestão empresarial, permitindo que nossos
            parceiros se concentrem em inovar e expandir, enquanto nossas
            soluções cuidam da eficiência operacional.
          </p>
        </div>
        <img src="/images/quemsomos.svg" alt="" />
      </div>
      <h2 className="titulo-valores">Nossos Valores</h2>
      <div className="lista-valores-container">
        <div className="lista-valores">
          {VALORES.map((valor, index) => (
            <div key={`${valor}-${index}`} className="valor">
              {valor}
            </div>
          ))}
        </div>
      </div>
      <div className="diferencial-container">
        <p className="diferencial-titulo">Diferencial</p>
        <p className="diferencial-texto">
          A FortePlus se diferencia pela facilidade de implementação e pelo
          suporte robusto. Sabemos que o tempo é essencial para os negócios e,
          por isso, oferecemos um tempo de implantação reduzido e uma plataforma
          fácil de usar. Além disso, nosso custo-benefício é ideal para empresas
          em crescimento​
        </p>
        <ul className="diferencial-itens">
          <li className="diferencial">
            <img src="/images/Diferencial3.png" alt="" />
            <p>Controle de Boleto</p>
          </li>
          <li className="diferencial">
            <img src="/images/Diferencial1.png" alt="" />
            <p>Integração com E-commerce</p>
          </li>
          <li className="diferencial">
            <img src="/images/Diferencial2.png" alt="" />
            <p> Sistema multiplataforma</p>
          </li>
          <li className="diferencial">
            <img src="/images/Diferencial3.png" alt="" />
            <p>Armazenamento em núvem</p>
          </li>
        </ul>
      </div>

      <div className="time-container">
        <h2 className="time-titulo">Faça Parte do Nosso Time</h2>
        <div className="time-content-vaga">
          <div className="time-info">
            <p>
              Estamos sempre em busca de talentos apaixonados por tecnologia e
              inovação. Se você quer fazer parte de uma equipe dinâmica e
              contribuir para o futuro da gestão empresarial, junte-se a nós!
            </p>
            <ul className="time-beneficios">
              <li>Ambiente de trabalho tranquilo</li>
              <li>Oportunidades de crescimento</li>
              <li>Projetos desafiadores</li>
              <li>Cultura de inovação</li>
              <li>Equipe engajada</li>
            </ul>
          </div>
          <form className="time-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Seu nome"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Seu e-mail"
              required
            />
            <input
              type="text"
              name="position"
              value={formData.position}
              onChange={handleInputChange}
              placeholder="Cargo de interesse"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Por que você quer se juntar à nossa equipe?"
              required
            ></textarea>
            <ReCAPTCHA
              sitekey="6LfLv1AqAAAAAMs0LMmaQAbehBrP-SgVYHDiq9ov"
              onChange={(value) => setCaptchaValue(value)}
            />
            <button
              type="submit"
              className="submit-btn"
              disabled={!captchaValue}
            >
              Enviar Candidatura
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
