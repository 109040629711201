import "./subir.css";
import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

export default function Subir() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="subir">
      <button onClick={scrollToTop}>Voltar ao topo</button>
    </div>
  );
}
