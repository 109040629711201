import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./chatbot.css";
import { RiSendPlaneFill } from "react-icons/ri";

const WEBSOCKET_URL = "ws://217.196.63.180:54321";
const RECONNECT_DELAY = 2000;
const TYPING_SPEED = 10; // characters per second

// Configurações do sistema anti-spam
const RATE_LIMIT = {
  MAX_MESSAGES: 4,
  TIME_WINDOW: 5000, // 5 segundos
  BLOCK_DURATION: 5000, // 30 segundos
};

const ChatComponent = ({ initialData }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockEndTime, setBlockEndTime] = useState(null);
  const [remainingBlockTime, setRemainingBlockTime] = useState(0);
  const intervalRef = useRef(null);

  const partitionMessage = useMemo(
    () => (message) => {
      return message.split(/(?<=[.?!;])\s+/);
    },
    []
  );

  const calculateTypingDelay = useMemo(
    () => (message) => {
      return (message.length / TYPING_SPEED) * 1000;
    },
    []
  );

  const sendMessageToWebSocket = useCallback((message) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      console.log("Enviando mensagem para o WebSocket:", message);
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not connected");
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Desculpe, não foi possível enviar sua mensagem. Por favor, tente novamente.",
          sender: "bot",
        },
      ]);
    }
  }, []);

  const handleWebSocketMessage = useCallback(
    async (event) => {
      console.log("Mensagem recebida do WebSocket:", event.data);
      const data = JSON.parse(event.data);
      const messageParts = partitionMessage(data.text);

      for (const part of messageParts) {
        await new Promise((resolve) => setTimeout(resolve, 500)); // Delay de 0.5s antes de mostrar "Digitando..."
        setIsTyping(true);
        await new Promise((resolve) =>
          setTimeout(resolve, calculateTypingDelay(part))
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: part, sender: "bot" },
        ]);
        setIsTyping(false);
      }
    },
    [partitionMessage, calculateTypingDelay]
  );

  const connectWebSocket = useCallback(() => {
    if (socketRef.current?.readyState === WebSocket.OPEN) return;

    console.log("Tentando conectar ao WebSocket...");
    socketRef.current = new WebSocket(WEBSOCKET_URL);

    socketRef.current.addEventListener("open", () => {
      console.log("WebSocket connection established");
      setIsConnected(true);
      if (initialData) {
        sendMessageToWebSocket({ type: "initial_motivo", text: initialData });
      }
    });

    socketRef.current.addEventListener("message", handleWebSocketMessage);

    socketRef.current.addEventListener("close", () => {
      console.log("WebSocket connection closed");
      setIsConnected(false);
      reconnectTimeoutRef.current = setTimeout(
        connectWebSocket,
        RECONNECT_DELAY
      );
    });

    socketRef.current.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
      setIsConnected(false);
    });
  }, [initialData, handleWebSocketMessage, sendMessageToWebSocket]);

  useEffect(() => {
    connectWebSocket();
    return () => {
      socketRef.current?.close();
      clearTimeout(reconnectTimeoutRef.current);
    };
  }, [connectWebSocket]);

  const checkSpam = useCallback(() => {
    const now = Date.now();
    const recentMessages = messageHistory.filter(
      (msg) => now - msg.timestamp < RATE_LIMIT.TIME_WINDOW
    );

    if (recentMessages.length >= RATE_LIMIT.MAX_MESSAGES) {
      setIsBlocked(true);
      const endTime = now + RATE_LIMIT.BLOCK_DURATION;
      setBlockEndTime(endTime);
      setRemainingBlockTime(Math.ceil(RATE_LIMIT.BLOCK_DURATION / 1000));
      return true;
    }

    return false;
  }, [messageHistory]);

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleSendMessage = useCallback(
    (e) => {
      e.preventDefault();
      if (inputValue.trim() && isConnected && !isBlocked) {
        if (!checkSpam()) {
          const userMessage = { text: inputValue, sender: "user" };
          setMessages((prevMessages) => [...prevMessages, userMessage]);
          sendMessageToWebSocket(userMessage);
          setMessageHistory((prev) => [
            ...prev,
            { timestamp: Date.now(), text: inputValue },
          ]);
          setInputValue("");
          setIsTyping(true);
        }
      }
    },
    [inputValue, isConnected, sendMessageToWebSocket, checkSpam, isBlocked]
  );

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, isTyping]);

  const renderMessage = useCallback(
    ({ text, sender }, index) => (
      <div key={index} className={`chat-message ${sender}`}>
        <div className="message-avatar">
          <img src="/favicon.png" alt={`${sender} avatar`} />
        </div>
        <div className="message-text">{text}</div>
      </div>
    ),
    []
  );

  useEffect(() => {
    if (isBlocked) {
      intervalRef.current = setInterval(() => {
        const now = Date.now();
        if (now >= blockEndTime) {
          setIsBlocked(false);
          setBlockEndTime(null);
          setRemainingBlockTime(0);
          clearInterval(intervalRef.current);
        } else {
          const remaining = Math.ceil((blockEndTime - now) / 1000);
          setRemainingBlockTime(remaining);
        }
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isBlocked, blockEndTime]);

  useEffect(() => {
    if (initialData && isConnected) {
      const initialMessage = `Olá ${initialData.nome}! Entendi que você trabalha na empresa ${initialData.empresa} no setor de ${initialData.setor}. Seu principal desafio é ${initialData.desafio}. Vou te mostrar como o FortePlus pode ajudar a resolver esse desafio.`;
      sendMessageToWebSocket({ type: "bot", text: initialMessage });
    }
  }, [initialData, isConnected, sendMessageToWebSocket]);
  useEffect(() => {
    if (initialData && isConnected) {
      const initialMessage = `Olá ${initialData.nome}! Entendi que você trabalha na empresa ${initialData.empresa} no setor de ${initialData.setor}. Seu principal desafio é ${initialData.desafio}. Vou te mostrar como o FortePlus pode ajudar a resolver esse desafio.`;
      sendMessageToWebSocket({ type: "bot", text: initialMessage });
    }
  }, [initialData, isConnected, sendMessageToWebSocket]);

  return (
    <div className="chat-container">
      <div className="chat-messages" ref={messagesContainerRef}>
        <p
          className="connection-status"
          style={{
            margin: "1rem auto",
            textAlign: "center",
            fontSize: "0.8rem",
            width: "80%",
            color: "#555",
          }}
        >
          {isConnected
            ? "Aproveite para tirar suas dúvidas com nosso assistente virtual."
            : "Tentando conectar ao assistente virtual..."}
        </p>
        {messages.map(renderMessage)}
        {isTyping && (
          <div className="chat-message bot">
            <div className="message-avatar">
              <img src="/favicon.png" alt="bot avatar" />
            </div>
            <div className="typing">
              Digitando<span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form className="chat-input-container" onSubmit={handleSendMessage}>
        <input
          type="text"
          className="chat-input"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={
            isBlocked
              ? `Aguarde ${remainingBlockTime} segundos antes de enviar...`
              : "Digite sua mensagem..."
          }
          disabled={!isConnected || isBlocked}
        />
        <button
          type="submit"
          className="chat-send-button"
          disabled={!isConnected || isBlocked || !inputValue.trim()}
        >
          <RiSendPlaneFill />
        </button>
      </form>
      {isBlocked && (
        <div className="spam-warning">
          Você está enviando mensagens muito rapidamente. Por favor, aguarde
          {remainingBlockTime} segundos antes de enviar mais mensagens.
        </div>
      )}
    </div>
  );
};

export default ChatComponent;
