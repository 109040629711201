import React, { useState, useRef, useEffect } from "react";
import "./comercio.css";
import ContatoModal from "../../../componentes/ContatoModal";

export default function Comercio() {
  const [activeTab, setActiveTab] = useState("caracteristicas");

  const caracteristicas = [
    { icon: "fa-file-invoice", title: "Orçamentos", description: "Elabore e atualize orçamentos rapidamente até se tornarem pedidos de venda" },
    { icon: "fa-cash-register", title: "FortePlus PDV", description: "Sistema moderno para frente de loja com emissão rápida de NFCe" },
    {icon: "fa-money-bill-wave", title: "Fechamento de Caixa", description: "Tenha o controle de suas vendas diariamente, por meio e forma de pagamento rapidamente."},
    { icon: "fa-shopping-cart", title: "E-commerce", description: "Integração com as principais plataformas de e-commerce do mercado" },
    {icon: "fa-chart-line", title: "Gestão Financeira", description: "Análise de demonstrativos, DRE, extrato bancário e muito mais"},
    { icon: "fa-file-alt", title: "SPED", description: "Compartilhamento padronizado de dados contábeis e fiscais para agilizar o gerenciamento tributário." },
    { icon: "fa-file-invoice", title: "Emissão de NF-e e NFS-e", description: "Automatize a emissão de notas fiscais com importação de dados do sistema e substituição tributária automática."},
    { icon: "fa-shopping-cart", title: "Compras", description: "Importação de dados da NF de entrada agiliza gerenciamento de compras e estoque."},
  ];

  const beneficios = [
    { icon: "fa-tachometer-alt", title: "Aumento de Eficiência", description: "Otimize processos de vendas, financeiros e fiscais, reduzindo drasticamente o tempo gasto em tarefas administrativas" },
    { icon: "fa-chart-pie", title: "Visão Completa do Negócio", description: "Obtenha insights valiosos com relatórios detalhados e dashboards personalizáveis, desde o PDV até a gestão financeira" },
    { icon: "fa-sync", title: "Integração Total", description: "Todos os módulos integrados para uma gestão sem falhas, desde o e-commerce até o SPED" },
    { icon: "fa-lock", title: "Segurança e Conformidade", description: "Proteção avançada para suas informações comerciais e conformidade com as exigências fiscais e tributárias" },
    { icon: "fa-rocket", title: "Crescimento Acelerado", description: "Expanda seu negócio com facilidade, integrando vendas online e offline com gestão eficiente de estoque e finanças" },
    { icon: "fa-users", title: "Melhoria no Atendimento ao Cliente", description: "Acesso rápido a informações de clientes e histórico de compras para um atendimento personalizado e eficiente" },
    { icon: "fa-box", title: "Controle de Estoque Otimizado", description: "Gestão precisa de estoque com alertas de reposição e previsão de demanda para evitar perdas e rupturas" },
    { icon: "fa-mobile-alt", title: "Mobilidade", description: "Acesse informações importantes do seu negócio de qualquer lugar, a qualquer momento, através de dispositivos móveis" },
  ];


  const carouselImages = [
    "https://images.unsplash.com/photo-1556740738-b6a63e27c4df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cG9pbnQlMjBvZiUyMHNhbGV8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1553413077-190dd305871c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aW52ZW50b3J5fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmluYW5jaWFsfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZWNvbW1lcmNlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFuYWdlbWVudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cmVwb3J0c3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
  ];

  const carouselRef = useRef(null);
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      startX = e.pageX - carousel.offsetLeft;
      scrollLeft = carousel.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
    };

    const handleMouseUp = () => {
      isDown = false;
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - carousel.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      carousel.scrollLeft = scrollLeft - walk;
    };

    const handleTouchStart = (e) => {
      isDown = true;
      startX = e.touches[0].pageX - carousel.offsetLeft;
      scrollLeft = carousel.scrollLeft;
    };

    const handleTouchEnd = () => {
      isDown = false;
    };

    const handleTouchMove = (e) => {
      if (!isDown) return;
      const x = e.touches[0].pageX - carousel.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      carousel.scrollLeft = scrollLeft - walk;
    };

    carousel.addEventListener('mousedown', handleMouseDown);
    carousel.addEventListener('mouseleave', handleMouseLeave);
    carousel.addEventListener('mouseup', handleMouseUp);
    carousel.addEventListener('mousemove', handleMouseMove);

    carousel.addEventListener('touchstart', handleTouchStart, { passive: true });
    carousel.addEventListener('touchend', handleTouchEnd);
    carousel.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      carousel.removeEventListener('mousedown', handleMouseDown);
      carousel.removeEventListener('mouseleave', handleMouseLeave);
      carousel.removeEventListener('mouseup', handleMouseUp);
      carousel.removeEventListener('mousemove', handleMouseMove);

      carousel.removeEventListener('touchstart', handleTouchStart);
      carousel.removeEventListener('touchend', handleTouchEnd);
      carousel.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  // Reinicia o autoplay após 5 segundos de inatividade
  useEffect(() => {
    if (!autoplay) {
      const timer = setTimeout(() => setAutoplay(true), 5000);
      return () => clearTimeout(timer);
    }
  }, [autoplay]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="comercio-container">
      <header className="comercio-header">
        <div className="header-content">
          <h1>Gestão de Varejo Simplificada</h1>
          <p>FortePlus: A solução completa que você precisa para otimizar suas vendas e atendimento ao cliente</p>
          <button className="cta-button" onClick={openModal}>Fale com um consultor</button>
        </div>
        <div className="header-image">
          <img src="/images/logo-simples.png" alt="FortePlus" />
        </div>
      </header>

      <section className="carousel-section">
        <div 
          className="carousel" 
          ref={carouselRef}
          onMouseEnter={() => setAutoplay(false)}
          onMouseLeave={() => setAutoplay(true)}
        >
          {carouselImages.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Varejo ${index + 1}`} />
            </div>
          ))}
        </div>
      </section>

      <section className="features-section">
        <div className="tab-buttons">
          <button
            className={activeTab === "caracteristicas" ? "active" : ""}
            onClick={() => setActiveTab("caracteristicas")}
          >
            Características
          </button>
          <button
            className={activeTab === "beneficios" ? "active" : ""}
            onClick={() => setActiveTab("beneficios")}
          >
            Benefícios
          </button>
        </div>
        <div className="tab-content">
          {activeTab === "caracteristicas" && (
            <div className="features-grid">
              {caracteristicas.map((item, index) => (
                <div key={index} className="feature-item">
                  <i className={`fas ${item.icon}`}></i>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          )}
          {activeTab === "beneficios" && (
            <div className="features-grid">
              {beneficios.map((item, index) => (
                <div key={index} className="feature-item">
                  <i className={`fas ${item.icon}`}></i>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <section className="estatisticas-section">
        <h2>FortePlus em Números</h2>
        <div className="estatisticas-grid">
          <div className="estatistica-item">
            <h3>5000+</h3>
            <p>Clientes Satisfeitos</p>
          </div>
          <div className="estatistica-item">
            <h3>30+</h3>
            <p>Integrações</p>
          </div>
          <div className="estatistica-item">
            <h3>24/7</h3>
            <p>Suporte Técnico</p>
          </div>
          <div className="estatistica-item">
            <h3>30%</h3>
            <p>Aumento Médio em Eficiência</p>
          </div>
        </div>
      </section>


      <section className="faq-section">
        <h2>Perguntas Frequentes</h2>
        <div className="faq-grid">
          <details>
            <summary>Como o FortePlus pode melhorar minha gestão de estoque?</summary>
            <p>O Forteplus oferece um controle de estoque eficiente, permitindo a visualização em tempo real e a atualização constante das quantidades disponíveis.</p>
          </details>
          <details>
            <summary>O sistema é compatível com meu e-commerce?</summary>
            <p>Sim, o FortePlus integra-se com as principais plataformas de e-commerce, garantindo sincronização de estoque e pedidos em tempo real.</p>
          </details>
          {/* Adicione mais perguntas conforme necessário */}
        </div>
      </section>

      <section className="cta-section">
        <div className="cta-content">
          <h2>Veja todas as funcionalidades</h2>
          <p>Confira todas as funcionalidades do FortePlus e como ele pode ajudar seu negócio.</p>
          <a href="/funcionalidades"><button className="cta-button">Ver funcionalidades</button></a>
        </div>
      </section>

      <ContatoModal isOpen={isModalOpen} onClose={closeModal} nomeTela="Comércio" />
    </div>
  );
}



