import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";

// Componentes
import Header from "./componentes/Header";
import Subir from "./componentes/subir";
import { Footer } from "componentes/footer";
import CookieConsent from "componentes/CookieConsent";

// Páginas
import Home from "./pages/Home/Home";
import QuemSomos from "./pages/QuemSomos/QuemSomos";
import Solucoes from "./pages/Solucoes/Solucoes";
import Funcionalidades from "./pages/Funcionalidades/Funcionalidades";
import Blog from "./pages/Blog/Blog";
import NotFound from "./pages/NotFound";
import CreatePost from "pages/CreatePost";
import PoliticaPrivacidade from "pages/politica";
import TermosDeServico from "pages/termos";
// import Login from './pages/Login';


function App() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <Router>
      <div className="Inside-App">
        <Helmet>

          <html lang="pt-BR" />
          <meta name="google-site-verification" content="v4gOhwSowHOKoq3cztklVd60Z1iE0BrGwVndrsg_GnI" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="index, follow" />
          <meta property="og:site_name" content="FortePlus Sistemas" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="pt-BR" />
          <link rel="icon" href="./favicon.png" />
          <link rel="canonical" href="https://www.forteplus.vercel.app" />
          <meta
            http-equiv="Content-Security-Policy"
            content="
      default-src 'self';
      script-src 'self' 'unsafe-inline' https://www.google.com https://www.gstatic.com;
      object-src 'none';
      base-uri 'none';
      style-src 'self' 'unsafe-inline' https://www.google.com https://www.gstatic.com;
      img-src 'self' data: https:;
      font-src 'self' https:;
      connect-src 'self' https:;
      frame-src 'self' https://www.google.com;
    "
          />

          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-SEU_ID_DO_PUBLISHER"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quem-somos" element={<QuemSomos />} />
          <Route path="/solucoes" element={<Solucoes />} />
          <Route path="/solucoes/:categoria" element={<Solucoes />} />
          <Route path="/funcionalidades" element={<Funcionalidades />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/post-admin" element={<CreatePost />} />
          <Route
            path="/politica-de-privacidade"
            element={<PoliticaPrivacidade />}
          />
          <Route
            path="/termos-de-servico"
            element={<TermosDeServico />}
          />
          {/*           <Route path="/admin" element={<Login/>}/>
          <Route path="/login" element={<Login />} />
          <Route path="/create-post" element={<CreatePost />} />
 */}{" "}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Subir isVisible={isVisible} />
      <CookieConsent />
      <Footer />
    </Router>
  );
}

export default App;
