import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

function CookieConsent() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (consentGiven === 'true') {
      setVisible(false);
    } else {
    
      setVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setVisible(false);
  };
  const declineCookies = () => {
    localStorage.setItem('cookieConsent', 'false');

    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="cookie-consent">
      <div className="cookie-consent-content">
        <h3>🍪 Usamos cookies</h3>
        <p>
          Este site usa cookies para melhorar sua experiência. Ao continuar navegando, 
          você concorda com o uso de cookies.
        </p>
        <div className="cookie-consent-actions">
          <button onClick={acceptCookies} className="accept-button">Aceitar</button>
          <button onClick={declineCookies} className="decline-button">Recusar</button>
        </div>
      </div>
    </div>
  );
}

export default CookieConsent;