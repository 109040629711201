import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./header.css";
import JornadaInicio from "../JornadaInicio";
import ChatComponent from "../chatbot";
import ContatoModal from "componentes/ContatoModal";
import PlanosModal from "../planos";

export default function Header() {
  const [modalOpen, setModalOpen] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isJornadaOpen, setIsJornadaOpen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [userData, setUserData] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleComecarClick = () => {
    setIsJornadaOpen(true);
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const handleJornadaComplete = (data) => {
    setUserData(data);
    setIsJornadaOpen(false);
    setShowChat(true);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isContatoModalOpen, setIsContatoModalOpen] = useState(false);

  const openContatoModal = () => setIsContatoModalOpen(true);
  const closeContatoModal = () => setIsContatoModalOpen(false);

  return (
    <>
      <div className="telefone-container">
        <a
          href="tel:+553135821410"
          className="telefone-header"
          name="Telefone de contato"
          style={{ gap: "10px", display: "flex", alignItems: "center" }}
        >
          <i className="fas fa-phone"></i> (31) 3582-1410
        </a>
{/*         <a 
          href="https://maps.app.goo.gl/pTySzUpHxWMYNhRj6"
          className="endereco-header"
          name="Endereço"
          style={{ gap: "10px", display: "flex", alignItems: "center" }}
        >
          <i className="fas fa-location-dot"></i> Belo Horizonte, R. Queluzita, 34 (31910-252)
        </a> */}
      </div>
      <header className="header-header">
        <NavLink to="/">
          <div className="logo">
            <img src="/logo.png" alt="FortePlus Logo" />
          </div>
        </NavLink>

        <div className={`nav-container ${isMenuOpen ? "active" : ""}`}>
          <nav className="header-nav">
            <ul>
              <li>
                <NavLink to="/" end onClick={toggleMenu}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/quem-somos" onClick={toggleMenu}>
                  Quem Somos
                </NavLink>
              </li>

              <li>
                <NavLink to="/solucoes" onClick={toggleMenu}>
                  Soluções
                </NavLink>
              </li>

              <li>
                <NavLink to="/funcionalidades" onClick={toggleMenu}>
                  Funcionalidades
                </NavLink>
              </li>

              <li>
                <NavLink to="/blog" onClick={toggleMenu}>
                  Blog
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="contato desktop-only">
            <span onClick={openContatoModal}>Contato</span>
            <button onClick={openModal}>Planos</button>
          </div>
        </div>
        <div className="contato">
          <span onClick={openContatoModal}>Contato</span>
          <button onClick={openModal} className="btn-comecar">
            Melhores Planos
          </button>
        </div>
        <button
          className={`hamburger ${isMenuOpen ? "active" : ""}`}
          onClick={toggleMenu}
          name="Menu"
          aria-label="Menu"
          aria-expanded={isMenuOpen}
        >
          <svg viewBox="0 0 100 80" width="40" height="40" aria-hidden="true">
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </button>
      </header>
      <JornadaInicio
        isOpen={isJornadaOpen}
        onClose={() => setIsJornadaOpen(false)}
        onComplete={handleJornadaComplete}
      />
      {showChat && (
        <div className="chat-wrapper">
          <ChatComponent initialData={userData} />
          <button onClick={() => setShowChat(false)} className="close-chat">
            Fechar Chat
          </button>
        </div>
      )}
      <PlanosModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <ContatoModal
        isOpen={isContatoModalOpen}
        onClose={closeContatoModal}
      />
    </>
  );
}
