import React from "react";
import { Helmet } from "react-helmet";
import "./politica.css";

const PoliticaPrivacidade = () => {
  return (
    <div className="politica-privacidade">
      <Helmet>
        <title>Política de Privacidade - FortePlus</title>
        <meta
          name="description"
          content="Política de Privacidade da FortePlus. Saiba como tratamos seus dados pessoais e protegemos sua privacidade."
        />
      </Helmet>
      <h1>Política de Privacidade</h1>
      <p className="last-updated">Última atualização: 07 de outubro de 2023</p>

      <section>
        <h2>1. Introdução</h2>
        <p>
          A FortePlus valoriza a privacidade de nossos usuários e está
          comprometida em proteger seus dados pessoais. Esta Política de
          Privacidade descreve como coletamos, usamos, armazenamos e protegemos
          suas informações pessoais neste site em conformidade com a Lei Geral de Proteção
          de Dados (LGPD) e outras legislações aplicáveis.
        </p>
      </section>

      <section>
        <h2>2. Coleta de Dados</h2>
        <p>
          Coletamos informações que você nos fornece diretamente, incluindo, mas
          não se limitando a:
        </p>
        <ul>
          <li>Nome completo</li>
          <li>Endereço de e-mail</li>
          <li>Número de telefone</li>
          <li>Endereço físico</li>
        </ul>
        <p>
          Por questões de segurança, também podemos coletar informações automaticamente, como seu endereço
          IP, tipo de navegador e dados de uso do site.
        </p>
      </section>

      <section>
        <h2>3. Uso de Dados</h2>
        <p>Utilizamos seus dados pessoais para os seguintes propósitos:</p>
        <ul>
          <li>Fornecer e melhorar nossos serviços</li>
          <li>Processar transações e enviar notificações relacionadas</li>
          <li>
            Comunicar-nos com você sobre atualizações, ofertas e promoções
          </li>
          <li>Personalizar sua experiência em nosso site</li>
          <li>Cumprir obrigações legais e regulatórias</li>
          <li>Aumentar a sua e a nossa segurança</li>
        </ul>
      </section>

      <section>
        <h2>4. Compartilhamento de Dados</h2>
        <p>
          A FortePlus não vende, aluga ou comercializa suas informações
          pessoais. Podemos compartilhar seus dados com:
        </p>
        <ul>
          <li>
            Prestadores de serviços que nos auxiliam na operação do negócio
          </li>
          <li>Parceiros de negócios, com seu consentimento prévio</li>
          <li>Autoridades governamentais, quando exigido por lei</li>
        </ul>
      </section>

      <section>
        <h2>5. Segurança de Dados</h2>
        <p>
          Implementamos medidas de segurança técnicas e organizacionais
          rigorosas para proteger seus dados pessoais, incluindo:
        </p>
        <ul>
          <li>Criptografia de dados em trânsito e em repouso</li>
          <li>Controles de acesso estritos</li>
          <li>Monitoramento contínuo de segurança</li>
        </ul>
      </section>

      <section>
        <h2>6. Uso de Cookies</h2>
        <p>
          Nosso site utiliza cookies para melhorar sua experiência de navegação e fornecer funcionalidades personalizadas. Cookies são pequenos arquivos de texto armazenados no seu dispositivo quando você visita nosso site.
        </p>
        <p>Utilizamos os seguintes tipos de cookies:</p>
        <ul>
          <li>Cookies essenciais: necessários para o funcionamento básico do site</li>
          <li>Cookies de desempenho: nos ajudam a entender como os visitantes interagem com o site</li>
          <li>Cookies de funcionalidade: permitem que o site lembre suas escolhas e preferências</li>
          <li>Cookies de publicidade: utilizados para fornecer anúncios mais relevantes</li>
        </ul>
        <p>
          Você pode gerenciar suas preferências de cookies através das configurações do seu navegador. No entanto, desabilitar certos cookies pode afetar a funcionalidade do site.
        </p>
      </section>

      <section>
        <h2>7. Alterações na Política</h2>
        <p>
          Podemos atualizar esta Política de Privacidade periodicamente para
          refletir mudanças em nossas práticas ou por outros motivos
          operacionais, legais ou regulatórios. Recomendamos que você revise
          esta página regularmente para se manter informado sobre nossas
          práticas de privacidade.
        </p>
      </section>

      <section>
        <h2>8. Contato</h2>
        <p>
          Se você tiver dúvidas, preocupações ou solicitações relacionadas a
          esta Política de Privacidade ou ao tratamento de seus dados pessoais,
          entre em contato conosco:
        </p>
        <div className="contact-info">
          <p>E-mail: contato@forteplus.com.br</p>
          <p>Telefone: +55 31 3582-1410</p>
          <p>Endereço: Rua Quelizita, 34 Belo Horizonte - MG, 31910-252</p>
        </div>
      </section>
    </div>
  );
};

export default PoliticaPrivacidade;
