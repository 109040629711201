import "./time.css";
import React from "react";

export function BlocoTime(props) {
  return (
    <div className="time-item">
      <div className="time-icon">
        <img src={props.url} alt={props.titulo} />
      </div>
      <div className="time-content">
        <h3 className="titulo-time">{props.titulo}</h3>
        <p className="desc-time">{props.desc}</p>
        <a href={props.link}>Saiba mais</a>
      </div>
    </div>
  );
}
