import React, { useState, useMemo, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import { scroller } from "react-scroll";

import "./Funcionalidades.css";
import { FaRocket, FaChartLine, FaShieldAlt, FaSyncAlt, FaMoneyCheckAlt } from "react-icons/fa";
import ContatoModal from "componentes/ContatoModal";

// Mova as constantes para fora do componente
const funcionalidadesDetalhadas = [
  {
    titulo: "EMISSÃO DE NOTA FISCAL",
    descricao:
      "Emissão de Notas Fiscais de Produtos e de Serviço Eletrônicas (NF-e / NFS-e) de forma simples e rápida com apenas alguns cliques.",
    categoria: "Fiscal",
  },
  {
    titulo: "NFCe",
    descricao:
      "O PDV FortePlus emite NFCe automaticamente direto no caixa, destinada a consumidor final. Especial para varejos que precisam de vendas rápidas.",
    categoria: "Fiscal",
  },
  {
    titulo: "BOLETO BANCÁRIO",
    descricao:
      "Emissão de boletos bancários de forma simples e rápida. Sistema gera integração CNAB para remessa e retorno bancários, para diversas instituições financeiras.",
    categoria: "Financeiro",
  },
  {
    titulo: "FLUXO DE CAIXA",
    descricao:
      "Fluxo de Caixa é uma excelente ferramenta para a empresa saber exatamente está a saúde financeira da organização, possibilitando analisar períodos e contas/caixas.",
    categoria: "Financeiro",
  },
  {
    titulo: "ORDEM DE SERVIÇO",
    descricao:
      "Controle de processos de serviços por clientes, gestão de produtos e serviços a serem trabalhados no atendimento ao cliente, bem como gestão de executantes na OS. Liberação de serviços parciais gerando NFE e Notas de Serviços.",
    categoria: "Vendas",
  },
  {
    titulo: "CONTAS A RECEBER/PAGAR",
    descricao:
      "Gestão completa do setor financeiro – baixas totais, parciais, pagamento e recebimentos antecipados, controle de cheques, cartões, processos de renegociação, geração de boletos, remessa para débito em conta, borderô e convênios e muito mais!",
    categoria: "Financeiro",
  },
  {
    titulo: "ORDEM DE PRODUÇÃO",
    descricao:
      "Controle de produtos acabados, intermediários, insumos, embalagens, emissão de etiquetas de controle, gestão de controle de processos da qualidade e planejamento e controle de produção, relacionado a análises de estoque mínimo, lote econômico e vendas por períodos.",
    categoria: "Produção",
  },
  {
    titulo: "MANIFESTO DE TRANSPORTE",
    descricao:
      "O módulo de manifesto de transporte possibilita a empresa realizar os transportes de suas mercadorias vendidas ou de compras de forma muito assertiva, gerando o documento para veículos próprios ou de terceiros, com possibilidades de gerenciamento de rotas.",
    categoria: "Vendas",
  },
  {
    titulo: "MANIFESTO DE NFe",
    descricao:
      "Realizar o evento que o destinatário de uma Nota Fiscal Eletrônica emite visando informar ao fisco sobre o andamento de uma operação de produtos e/ou serviços, de forma prática e pontual, gerando informações de conformidade ou não referentes as notas recebidas.",
    categoria: "Fiscal",
  },
  {
    titulo: "CONTROLE DE ESTOQUE",
    descricao:
      "O ERP FortePlus permite total controle sobre o seu estoque. Você pode atribuir sinalizações para compras, atualizar preços, personalizar produtos, gerar inventário e etc, bem como saber saldos de estoques totais, reservados e disponíveis, atrelados a gestão de custo.",
    categoria: "Estoque",
  },
  {
    titulo: "GESTÃO FISCAL",
    descricao:
      "Lançamento de SPED Fiscal, SPED Contribuições SINTEGRA, tudo seguindo com rigor a legislação brasileira e suas determinações, sempre atualizado. Lançamentos de notas de acordo com a ferramenta fácil incorporada ao FortePlus intitulada Memória Fiscal.",
    categoria: "Fiscal",
  },
  {
    titulo: "ATUALIZAÇÃO DE PREÇO",
    descricao:
      "O sistema conta com a tecnologia de atualização de preços de forma muito pontual. O cliente pode escolher por tabela de preço, grupo de produto gerando atualização por fator financeiro, em porcentagem e preço único de forma automática e muito fácil, para o gestor de compras/vendas.",
    categoria: "Vendas",
  },
  {
    titulo: "GESTÃO DE CONTRATOS",
    descricao:
      "Gerencie todos os documentos contratuais emitidos pela empresa. Possibilidade de gerenciar vencimentos de contratos, gestão de parcelas a vencer, fator de atualização contratual, emissão de textos personalizados por contratos e muito mais.",
    categoria: "Financeiro",
  },
  {
    titulo: "CONCILIAÇÃO BANCÁRIA",
    descricao:
      "O processo de conciliação bancária de sua empresa ficará mais fácil a partir dos módulos financeiro do sistema. Ele permite gerar integração com arquivos OFX ou de forma manual. O extrato bancário se torna relacional de acordo com os lançamentos.",
    categoria: "Financeiro",
  },
  {
    titulo: "GESTÃO DE COMPRAS",
    descricao:
      "Solicitação de compras, orçamentos e ordem de compras, bem como a interligação com a gestão de entrada de notas fiscais gerando inclusive ordem de contas a pagar totalmente integrados.",
    categoria: "Estoque",
  },
  {
    titulo: "INTEGRAÇÃO E-COMMERCE",
    descricao:
      "Integração completa do ERP com diversos sistemas de venda online do mercado, gerando controle de estoque, clientes, pedido de vendas, gestão de rastreabilidade de entregas tudo em um só lugar, sem contar com as integrações de Marketplace.",
    categoria: "Vendas",
  },
  {
    titulo: "CONTROLE DE LOTES",
    descricao:
      "Empresas que necessitam de controle de lotes conhecem de forma bem assertiva as particularidades desta operação. Portanto, o FortePlus Sistemas poderá auxiliar todas as rotinas de entrada, produção ou ajuste de lotes gerando rastreabilidade.",
    categoria: "Estoque",
  },
  {
    titulo: "AGENDA",
    descricao:
      "Controle e configuração de informações e compromissos na agenda da empresa, associadas a carteira de clientes, pedidos de vendas e funcionarios.",
    categoria: "Vendas",
  },
  {
    titulo: "ROMANEIO DE ENTREGA",
    descricao:
      "Informações pertinentes ao controle de entrega de produtos, como rotas, agrupamento e ordem de entrega, controle por geradores de de rota e cargas.",
    categoria: "Vendas",
  },
  {
    titulo: "RELATÓRIOS GERENCIAIS",
    descricao:
      "O sistema oferece uma série de relatórios pertinentes a cada tela de gerenciamento que fornecem um amplo controle das informações da empresa, tais como: balancete de verificação, gestão de baixas por plano de contas x centro de custos, relatórios de demonstrativo de resultados.",
    categoria: "Financeiro",
  },
  {
    titulo: "GESTÃO COM BUSINESS INTELIGENCE",
    descricao:
      "O FortePlus Sistemas possui uma integralidade com diversas ferramentas de BI existentes no mercado. Atualmente possuímos uma expertise em construção de painéis de gestão para os módulos financeiro, estoque, compras entre outros.",
    categoria: "Financeiro",
  },
  {
    titulo: "PIX",
    descricao:
      "O sistema conta com a tecnologia de pagamento instantâneo PIX, proporcionando mais agilidade e segurança nas transações financeiras.",
    categoria: "Financeiro",
  },
];

const funcionalidadesPrincipais = [
  "CONTROLE DE ESTOQUE",
  "GESTÃO DE COMPRAS",
  "ORDEM DE PRODUÇÃO",
  "CONTAS A RECEBER/PAGAR",
  "GESTÃO FISCAL",
  "ROMANEIO DE ENTREGA",
  "ORDEM DE SERVIÇO",
  "INTEGRAÇÃO E-COMMERCE",
  "EMISSÃO DE NOTA FISCAL",
  "BUSINESS INTELLIGENCE",
  "PIX",
];

const vantagens = [
  {
    titulo: "Agilidade",
    descricao:
      "O FortePlus busca agilizar os processos e a tomada de decisões para a empresa a partir de telas objetivas e de manipulação fácil.",
  },
  {
    titulo: "Atualizado",
    descricao:
      "O FortePlus Sistemas está sempre em atualização principalmente para as novas legislações fiscais brasileiras. Gerando conforto e agilidade nas operações no sistema.",
  },
  {
    titulo: "Interface",
    descricao:
      "A interface do sistema é de fácil entendimento e usabilidade. Assim, as empresas terão fácil adequação em repasse da utilização dos processos aos novos colaboradores.",
  },
  {
    titulo: "Segurança",
    descricao:
      "O FortePlus conta com correções e atualizações periódicas para sempre melhorar o sistema e se adequar às necessidades do cliente, gerando segurança as operações.",
  },
];

const categories = [
  "Todos",
  "Financeiro",
  "Estoque",
  "Vendas",
  "Fiscal",
  "Produção",
];

export default function Funcionalidades() {
  const [activeCategory, setActiveCategory] = useState("Todos");
  const [searchTerm, setSearchTerm] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const funcionalidadesDetalhadasRef = useRef(null);

  const filteredAndSortedFuncionalidades = useMemo(() => {
    return funcionalidadesDetalhadas
      .filter(
        (func) =>
          (activeCategory === "Todos" || func.categoria === activeCategory) &&
          (func.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
            func.descricao.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      .sort((a, b) => {
        const aTitle = a.titulo.toLowerCase();
        const bTitle = b.titulo.toLowerCase();
        const aDesc = a.descricao.toLowerCase();
        const bDesc = b.descricao.toLowerCase();
        const searchLower = searchTerm.toLowerCase();

        // Prioridade para correspondências no título
        if (aTitle.includes(searchLower) && !bTitle.includes(searchLower))
          return -1;
        if (!aTitle.includes(searchLower) && bTitle.includes(searchLower))
          return 1;

        // Se ambos ou nenhum título corresponder, verifique a descrição
        if (aDesc.includes(searchLower) && !bDesc.includes(searchLower))
          return -1;
        if (!aDesc.includes(searchLower) && bDesc.includes(searchLower))
          return 1;

        // Se ainda empatado, mantenha a ordem original
        return 0;
      });
  }, [activeCategory, searchTerm]);

  const handleCategoryChange = useCallback((category) => {
    setActiveCategory(category);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleFuncionalidadeClick = useCallback((funcionalidade) => {
    setSearchTerm(funcionalidade);
    scroller.scrollTo("funcionalidadesDetalhadas", {
      duration: 800,
      smooth: true,
      offset: -50 // ajuste este valor conforme necessário para compensar headers fixos
    });
  }, []);

  return (
    <div className="funcionalidades-container-page">
            <Helmet>
        <title>Funcionalidades do FortePlus - Sistema ERP Completo</title>
        <meta name="description" content="Explore as funcionalidades do FortePlus: gestão de estoque, vendas, produção, financeiro, fiscal e muito mais. ERP completo para sua empresa." />
        <meta name="keywords" content="ERP, funcionalidades, gestão de estoque, vendas, produção, financeiro, fiscal" />
        <meta property="og:title" content="Funcionalidades do FortePlus - Sistema ERP Completo" />
        <meta property="og:description" content="ERP completo com gestão de estoque, vendas, produção, financeiro, fiscal e muito mais." />
        <meta property="og:image" content="https://www.forteplus.com.br/images/og-funcionalidades.png" />
        <meta property="og:url" content="https://www.forteplus.com.br/funcionalidades" />
        <link rel="canonical" href="https://www.forteplus.com.br/funcionalidades" />
      </Helmet>

      <header className="funcionalidades-header-page">
        <h1 className="funcionalidades-titulo-page">FUNCIONALIDADES</h1>
        <p className="funcionalidades-subtitulo-page">
          Descubra o poder do FortePlus Sistemas para o seu negócio!
        </p>
      </header>
      <section className="funcionalidades-principais-section-page">
        <h2 className="funcionalidades-section-title-page">
          Recursos Principais
        </h2>
        <div className="funcionalidades-principais-lista-page">
          {funcionalidadesPrincipais.map((func, index) => (
            <span 
              key={index} 
              className="funcionalidades-tag-page"
              onClick={() => handleFuncionalidadeClick(func)}
              style={{ cursor: 'pointer' }}
            >
              {func}
            </span>
          ))}
          <span className="funcionalidades-tag-highlight-page">
            E MUITO MAIS
          </span>
        </div>
      </section>
      <section className="funcionalidades-vantagens-section-page">
        <div className="funcionalidades-vantagens-content-page">
          <h2 className="funcionalidades-vantagens-titulo-page">
            Controle completo da sua empresa em um só lugar
          </h2>
          <div className="funcionalidades-vantagens-fluxo">
            {vantagens.map((vantagem, index) => (
              <div key={index} className="funcionalidades-vantagem-modulo">
                <div className="funcionalidades-vantagem-conector"></div>
                <div className="funcionalidades-vantagem-card">
                  <div className="funcionalidades-vantagem-icon">
                    {index === 0 && <FaRocket />}
                    {index === 1 && <FaSyncAlt />}
                    {index === 2 && <FaChartLine />}
                    {index === 3 && <FaShieldAlt />}
                    {index === 4 && <FaMoneyCheckAlt />}
                  </div>
                  <h3 className="funcionalidades-vantagem-titulo">
                    {vantagem.titulo}
                  </h3>
                  <p className="funcionalidades-vantagem-descricao">
                    {vantagem.descricao}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section 
        name="funcionalidadesDetalhadas" 
        className="funcionalidades-detalhadas-section-page"
      >
        <h2 className="funcionalidades-section-title-page">
          Recursos Detalhados
        </h2>
        <div className="funcionalidades-filter-page">
          <div className="funcionalidades-category-buttons-page">
            {categories.map((category) => (
              <button
                key={category}
                className={`funcionalidades-category-button-page ${
                  activeCategory === category
                    ? "funcionalidades-category-active-page"
                    : ""
                }`}
                onClick={() => {
                  handleCategoryChange(category);
                  setSearchTerm('');
                }}
              >
                {category}
              </button>
            ))}
          </div>
          <input
            type="text"
            placeholder="Buscar funcionalidade..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="funcionalidades-search-input-page"
          />
        </div>
        <div className="funcionalidades-grid-page">
          {filteredAndSortedFuncionalidades.map((func, index) => (
            <div key={index} className="funcionalidades-card-page">
              <h3 className="funcionalidades-card-titulo-page">
                {func.titulo}
              </h3>
              <p className="funcionalidades-card-descricao-page">
                {func.descricao}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className="funcionalidades-cta-section-page">
        <h2 className="funcionalidades-cta-titulo-page">
          Pronto para revolucionar sua gestão empresarial?
        </h2>
        <p className="funcionalidades-cta-descricao-page">
          Experimente o FortePlus Sistemas e leve sua empresa ao próximo nível!
        </p>
        <button className="funcionalidades-cta-button-page" onClick={openModal}>
          Solicite uma demonstração
        </button>
      </section>
      <footer className="funcionalidades-footer-page">
        <p className="funcionalidades-footer-texto-page">
          &copy; 2023 FortePlus Sistemas. Todos os direitos reservados.
        </p>
      </footer>
      <ContatoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        nomeTela="Funcionalidades"
      />
    </div>
  );
}
