import './termosDeServico.css'
export default function TermosDeServico() {

    return (
        <>
            <div className='TermosDeServico'>
                <h1>Termos de Serviço Detalhados</h1>

                <h2>IDENTIFICAÇÃO DA EMPRESA</h2>
                <p><strong>Nome da Empresa:</strong> Forteplus Sistemas</p>
                <p><strong>CNPJ:</strong> 14.719.891/0001-45</p>
                <p><strong>Endereço:</strong> Rua Queluzita, 34
                    Belo Horizonte - MG, 31170-679</p>

                <h2>DEFINIÇÕES E OBJETO DO CONTRATO</h2>
                <h3>1.1. Objeto</h3>
                <p>Este instrumento regula a cessão temporária de uso de software, serviços de manutenção e suporte técnico fornecidos pela EMPRESA ao USUÁRIO.</p>

                <h3>1.2. Softwares Abrangidos</h3>
                <ul>
                    <li>FORTEPLUS ERP</li>
                    <li>Módulos Adicionais especificados na Proposta Comercial</li>
                </ul>

                <h2>MODALIDADE DE CESSÃO DE USO</h2>
                <h3>2.1. A cessão de uso do software é:</h3>
                <ul>
                    <li>Não exclusiva</li>
                    <li>Intransferível</li>
                    <li>Por prazo indeterminado</li>
                    <li>Limitada aos termos deste instrumento</li>
                </ul>

                <h2>DIREITOS E OBRIGAÇÕES DA EMPRESA</h2>
                <h3>3.1. Manutenção de Software</h3>
                <p>A EMPRESA se compromete a realizar:</p>
                <ul>
                    <li>Correção de erros ou defeitos no desempenho do SOFTWARE</li>
                    <li>Atualização para novas versões do sistema</li>
                    <li>Implementação de melhoramentos e complementos</li>
                </ul>

                <h3>3.2. Serviços Não Cobertos pela Manutenção</h3>
                <p>Não estão incluídos serviços como:</p>
                <ul>
                    <li>Correção de erros por acidente ou uso indevido</li>
                    <li>Recuperação de arquivos corrompidos</li>
                    <li>Migração de dados entre ambientes</li>
                    <li>Desenvolvimento de rotinas adicionais</li>
                    <li>Customizações específicas</li>
                </ul>

                <h2>INSTALAÇÃO E TREINAMENTO</h2>
                <h3>4.1. Entrega do Software</h3>
                <p>Fornecimento de 01 (uma) cópia do SOFTWARE, documentação técnica impressa ou em meio digital, e treinamento conforme especificado no Pedido de Licença de Uso (PLU).</p>

                <h3>4.2. Condições de Treinamento</h3>
                <p>Realizado em horário comercial, local: dependências da EMPRESA ou remoto, prazo de 30 dias para utilização das horas de treinamento. Horas adicionais cobradas conforme tabela vigente.</p>

                <h2>SUPORTE TÉCNICO</h2>
                <h3>5.1. Canal de Solicitação</h3>
                <p>Solicitações por escrito, chamados via telefone/internet com formalização em 24 horas. Horário de atendimento: 08:30h às 18:00h (horário de Brasília), dias úteis, excluindo feriados.</p>

                <h3>5.2. Acordo de Nível de Serviço (SLA)</h3>
                <p>Prazo de 12 horas úteis para resposta. Informação sobre recursos e prazos necessários. Atendimento presencial mediante contrato específico.</p>

                <h2>RESPONSABILIDADES DO USUÁRIO</h2>
                <h3>6.1. Obrigações</h3>
                <ul>
                    <li>Ambiente técnico adequado</li>
                    <li>Controles operacionais suficientes</li>
                    <li>Planos de recuperação de sistemas</li>
                    <li>Medidas preventivas contra riscos</li>
                </ul>

                <h3>6.2. Restrições</h3>
                <p>É vedado ao USUÁRIO:</p>
                <ul>
                    <li>Modificar o SOFTWARE sem autorização</li>
                    <li>Copiar, ceder, sublicenciar ou transferir</li>
                    <li>Utilizar especificações para criar software similar</li>
                    <li>Alterar marcas, logotipos ou identificações</li>
                </ul>

                <h2>PROPRIEDADE INTELECTUAL</h2>
                <h3>7.1. Direitos</h3>
                <p>SOFTWARE é propriedade exclusiva da FORTEPLUS. Contém segredos de concepção e desenvolvimento, protegido por direitos autorais.</p>

                <h2>GARANTIA</h2>
                <h3>8.1. Condições de Garantia</h3>
                <p>30 dias contra defeitos de funcionamento, garantia de meios magnéticos. Não cobre:</p>
                <ul>
                    <li>Problemas por decisões baseadas no SOFTWARE</li>
                    <li>Negligência ou uso inadequado</li>
                    <li>Casos fortuitos ou força maior</li>
                    <li>Danos por vírus</li>
                    <li>Falhas de energia</li>
                </ul>

                <h2>PREÇOS E PAGAMENTO</h2>
                <h3>9.1. Condições Financeiras</h3>
                <p>Valores definidos em Proposta Comercial. Pagamento independente de pendências. Reajuste baseado no IGPM.</p>

                <h3>9.2. Inadimplência</h3>
                <ul>
                    <li>Multa de 2%</li>
                    <li>Juros de 1% ao mês</li>
                    <li>Suspensão de serviços após 15 dias</li>
                    <li>Possível rescisão após 60 dias</li>
                    <li>Inclusão em serviços de proteção ao crédito</li>
                </ul>

                <h2>PROTEÇÃO DE DADOS</h2>
                <h3>10.1. Tratamento de Dados Pessoais</h3>
                <p>Coleta criptografada, apenas dados necessários ao funcionamento, armazenamento vinculado ao contrato, possibilidade de manutenção após encerramento para fins legais.</p>

                <h2>VIGÊNCIA E RESCISÃO</h2>
                <h3>11.1. Duração</h3>
                <p>Contrato inicial de 12 meses. Renovação automática. Rescisão possível:</p>
                <ul>
                    <li>Por mútuo acordo</li>
                    <li>Mediante aviso de 60 dias</li>
                    <li>Por inadimplemento</li>
                </ul>

                <h3>11.2. Penalidades em Rescisão Antecipada</h3>
                <p>Pagamento de 30% das parcelas restantes. Devolução do SOFTWARE. Cessão de utilização.</p>

                <h2>DISPOSIÇÕES FINAIS</h2>
                <h3>12.1. Foro</h3>
                <p>Comarca de Belo Horizonte/MG. Renúncia de outro foro privilegiado.</p>

                <h3>12.2. Confidencialidade</h3>
                <p>Sigilo sobre dados e especificações técnicas. Responsabilidade proporcional ao prejuízo causado.</p>

                <p>Ao utilizar nossos serviços, o USUÁRIO declara ter lido, compreendido e aceito integralmente estes Termos de Serviço.</p>

                <p><strong>Última atualização:</strong> 10/12/2024</p>
            </div>
        </>)
}