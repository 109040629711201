import "./opiniao.css";
import React from "react";

export default function Opiniao(props) {
  return (
    <div className={`opiniao ${props.isEven ? "even" : "odd"}`}>
      <ul className="opiniao-container">
        <li className="cliente-infos">
          <div>
            <img src={props.imagem} alt="foto cliente" />
          </div>
          <div className="cliente-infos-text">
            <p className="cliente-nome">{props.nome}</p>
            <p className="cliente-empresa">{props.empresa}</p>
          </div>
        </li>
        <li className="cliente-opiniao">{props.opiniao}</li>
      </ul>
    </div>
  );
}
