import "./funcionalidades.css";
import React from "react";
import { useInView } from "../../hooks/useInView";

export default function Funcionalidades({ icone, titulo, descricao }) {
  const [ref, isInView] = useInView({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`funcionalidade-card ${isInView ? "in-view" : ""}`}
    >
      <div className="funcionalidade-icon-wrapper">
        {icone}
      </div>
      <h3 className="nome-funcionalidade">{titulo}</h3>
      <p className="descricao-funcionalidade">{descricao}</p>
    </div>
  );
}
