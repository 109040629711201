import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

export default function NotFound() {
  return (
    <div className="not-found-container">
      <h1 className="h1-404">404</h1>
      <h2 className="h2-404">Página não encontrada</h2>
      <p className="p-404">Desculpe, a página que você está procurando não existe.</p>
      <Link to="/" className="home-link-404">Voltar para a página inicial</Link>
    </div>
  );
}
