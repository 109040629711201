import React, { useState } from "react";
import "./agronegocio.css";
import ContatoModal from "../../../componentes/ContatoModal";

export default function AgroNegocio() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="agronegocio-container">
      <header className="agronegocio-header">
        <div className="header-content">
          <h1>Gestão ERP para Agronegócios</h1>
          <p>Com o FortePlus você possui o controle e acompanhamento dos processos gerenciais do seu negócio de forma mais automatizada, como o controle insumos, gestão de custos, de recursos e de pessoas, proporcionando uma gestão produtiva mais ágil.</p>
          <button className="cta-button" onClick={openModal}>Fale com um consultor</button>
        </div>
        <div className="header-image">
          <img src="/images/agronegocio.png" alt="Agronegócio" />
        </div>
      </header>

      <section className="features-section">
        <h2>Características do Sistema</h2>
        <div className="features-grid">
          <div className="feature-item">
            <i className="fas fa-money-bill-wave"></i>
            <h3>Fluxo de Caixa</h3>
            <p>Gestão e controle do fluxo financeiro completo da empresa.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-file-invoice-dollar"></i>
            <h3>Orçamentos</h3>
            <p>Controle de orçamento de produção, como compras e vendas realizadas para determinada safra.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-users-cog"></i>
            <h3>Controle de Funcionários</h3>
            <p>Controle operacional e administrativo do corpo de funcionários da empresa.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-sync"></i>
            <h3>Integração com Softwares Produtivos</h3>
            <p>O FortePlus oferece a integração com softwares produtivos.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-chart-line"></i>
            <h3>Financeiro</h3>
            <p>Todo o controle de suas finanças integrado com o gerenciamento de contas a receber e a pagar e o fluxo de caixa da sua empresa.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-credit-card"></i>
            <h3>Conciliação Bancária</h3>
            <p>O sistema faz a conciliação e controle das contas da empresa. Assim, você tem informações atualizadas das transações, além da consulta de extratos por período e tipos de transações bancárias.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-chart-pie"></i>
            <h3>Demonstrativo de Resultados</h3>
            <p>Acompanhamento em relatórios dos resultados gerais da empresa, como custos operacionais, custos e despesas fixas e variáveis, em um determinado período de tempo.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-shopping-bag"></i>
            <h3>Compras</h3>
            <p>A importação de informações direto da nota fiscal de entrada agiliza o processo de gerenciamento de suas compras e organização dos produtos e insumos em estoque.</p>
          </div>
        </div>
      </section>

      <section className="benefits-section">
        <h2>Benefícios do Sistema</h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <i className="fas fa-tachometer-alt"></i>
            <h3>Aumento de Eficiência</h3>
            <p>Otimize processos de gestão, reduzindo o tempo gasto em tarefas administrativas.</p>
          </div>
          <div className="benefit-item">
            <i className="fas fa-chart-pie"></i>
            <h3>Visão Completa do Negócio</h3>
            <p>Obtenha insights valiosos com relatórios detalhados e dashboards personalizáveis.</p>
          </div>
          <div className="benefit-item">
            <i className="fas fa-sync"></i>
            <h3>Integração Total</h3>
            <p>Todos os módulos integrados para uma gestão sem falhas.</p>
          </div>
          <div className="benefit-item">
            <i className="fas fa-lock"></i>
            <h3>Segurança e Conformidade</h3>
            <p>Proteção avançada para suas informações e conformidade com as exigências fiscais.</p>
          </div>
        </div>
      </section>

      <section className="jornada-agro">
        <h2>Sua Jornada no Agronegócio com FortePlus</h2>
        <div className="jornada-timeline">
          <div className="jornada-item">
            <div className="jornada-icon">
              <i className="fas fa-seedling"></i>
            </div>
            <div className="jornada-content">
              <h3>Conciliação Bancária</h3>
              <p>O sistema faz a conciliação e controle das contas da empresa.</p>
            </div>
          </div>
          <div className="jornada-item">
            <div className="jornada-icon">
              <i className="fas fa-file-invoice-dollar"></i>
            </div>
            <div className="jornada-content">
              <h3>Demonstrativo de Resultados</h3>
              <p>Relatórios dos resultados gerais da empresa como custos operacionais, custos e despesas fixas e variáveis.</p>
            </div>
          </div>
          <div className="jornada-item">
            <div className="jornada-icon">
              <i className="fas fa-truck"></i>
            </div>
            <div className="jornada-content">
              <h3>Manifesto de transporte</h3>
              <p>Possibilita realizar os transportes de suas mercadorias vendidas ou de compras de forma muito assertiva.</p>
            </div>
          </div>
          <div className="jornada-item">
            <div className="jornada-icon">
              <i className="fas fa-calendar-alt"></i>
            </div>
            <div className="jornada-content">
              <h3>Agenda</h3>
              <p>Controle e configuração de informações e compromissos na agenda, associadas a carteira de clientes, pedidos de vendas e funcionarios.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <div className="cta-content">
          <h2>Veja todas as funcionalidades</h2>
          <p>Confira todas as funcionalidades do FortePlus e como ele pode ajudar seu negócio.</p>
          <a href="/funcionalidades"><button className="cta-button">Ver funcionalidades</button></a>
        </div>
      </section>

      <ContatoModal isOpen={isModalOpen} onClose={closeModal} nomeTela="Agronegócio" />
    </div>
  );
}