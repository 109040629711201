import React, { useState, useRef,useEffect } from "react";
import "./jornadaInicio.css";
import ChatComponent from "../chatbot";

const JornadaInicio = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    empresa: "",
    setor: "",
    desafio: "",
  });
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Dados enviados:", formData);
    setShowChat(true);
    setStep(step + 1);
  };

  const steps = [
    {
      title: "Bem-vindo à sua jornada de sucesso!",
      content: (
        <div className="welcome-content">
          <h2>Descubra o poder da FortePlus</h2>
          <p>Estamos prestes a revolucionar a maneira como você gerencia seu negócio. Prepare-se para uma experiência única!</p>
          <button onClick={nextStep} className="btn-primary">Começar minha jornada</button>
        </div>
      ),
    },
    {
      title: "Conte-nos sobre você",
      content: (
        <form onSubmit={(e) => { e.preventDefault(); nextStep(); }}>
          <input
            type="text"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            placeholder="Seu nome"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Seu e-mail"
            required
          />
          <button type="submit" className="btn-primary">Próximo</button>
        </form>
      ),
    },
    {
      title: "Sua empresa",
      content: (
        <form onSubmit={(e) => { e.preventDefault(); nextStep(); }}>
          <input
            type="text"
            name="empresa"
            value={formData.empresa}
            onChange={handleChange}
            placeholder="Nome da sua empresa"
            required
          />
          <select
            name="setor"
            value={formData.setor}
            onChange={handleChange}
            required
          >
            <option value="">Selecione o setor</option>
            <option value="tecnologia">Tecnologia</option>
            <option value="saude">Saúde</option>
            <option value="educacao">Educação</option>
            <option value="varejo">Varejo</option>
            <option value="outro">Outro</option>
          </select>
          <button type="submit" className="btn-primary">Próximo</button>
        </form>
      ),
    },
    {
      title: "Estamos quase lá!",
      content: (
        <form onSubmit={handleSubmit}>
          <h3>Qual é o principal desafio da sua empresa?</h3>
          <select
            name="desafio"
            value={formData.desafio}
            onChange={handleChange}
            required
          >
            <option value="">Selecione um desafio</option>
            <option value="gestao_financeira">Gestão Financeira</option>
            <option value="controle_estoque">Controle de Estoque</option>
            <option value="atendimento_cliente">Atendimento ao Cliente</option>
            <option value="produtividade">Aumento de Produtividade</option>
            <option value="expansao">Expansão do Negócio</option>
            <option value="outro">Outro</option>
          </select>
          <p>Com base na sua resposta, prepararemos uma demonstração personalizada da FortePlus para atender às suas necessidades específicas.</p>
          <button type="submit" className="btn-primary">Iniciar Chat</button>
        </form>
      ),
    },
    {
      title: "Converse com nosso assistente",
      content: (
        <ChatComponent initialData={formData} />
      ),
    },
  ];
  const modalRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);


  if (!isOpen) return null;

  return (
    <div className="jornada-overlay">
      <div className="jornada-content" ref={modalRef}>
        <button className="jornada-close" onClick={onClose}>&times;</button>
        <div className="jornada-progress">
          {steps.map((_, index) => (
            <div key={index} className={`progress-step ${index <= step ? 'active' : ''}`} />
          ))}
        </div>
        <h1>{steps[step].title}</h1>
        {steps[step].content}
        {step > 0 && step < steps.length - 1 && (
          <button onClick={prevStep} className="btn-secondary">Voltar</button>
        )}
      </div>
    </div>
  );
};

export default JornadaInicio;